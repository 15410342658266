import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, ViewEncapsulation } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { CelumDialogModule, IconConfiguration } from '@celum/common-components';
import { CelumPipesModule } from '@celum/ng2base';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, CelumDialogModule, CelumPipesModule],
  selector: 'celum-confirmation-with-list',
  templateUrl: './confirmation-with-list.component.html',
  styleUrls: ['./confirmation-with-list.component.scss'],
  // Needed to style the statistics insite the text.
  encapsulation: ViewEncapsulation.None,
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ConfirmationWithListDialog {
  constructor(
    protected dialogRef: MatDialogRef<ConfirmationWithListDialog, boolean>,
    @Inject(MAT_DIALOG_DATA) protected config: ConfirmationWithListDialogConfiguration
  ) {}
}

export interface ConfirmationWithListDialogConfiguration {
  headerText: string;
  headerParams: { [key: string]: string };
  items: ConfirmationWithListDialogItem[];
  confirmButtonText: string;
}

export interface ConfirmationWithListDialogItem {
  icon: IconConfiguration;
  text: string;
  translationParams?: { [key: string]: string | number };
}
