import { Injectable } from '@angular/core';
import { select, Store } from '@ngrx/store';
import { Observable } from 'rxjs';

import { PageContext } from '@celum/common-components';

import { UpdateContextAction } from '../state/magic-button-actions';
import { getContext } from '../state/magic-button-reducers';

@Injectable({ providedIn: 'root' })
export class MagicButtonDataProvider {

  constructor(private store: Store<any>) {

  }

  public init(context: PageContext): void {
    this.store.dispatch(new UpdateContextAction(context));
  }

  public updateContext(context: PageContext): void {
    this.store.dispatch(new UpdateContextAction(context));
  }

  public getPageContext(): Observable<PageContext> {
    return this.store.pipe(select(getContext));
  }
}
