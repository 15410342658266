import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { map, Observable, switchMap, take } from 'rxjs';

import { UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { LibraryFindOptions, LibraryService } from '@celum/libraries/shared';

export interface LibraryCountState {
  totalNumberOfLibraries: number;
  totalNumberOfOwnLibraries: number;
  totalNumberOfFilteredLibraries: number;
}

@Injectable({
  providedIn: 'root'
})
export class LibraryCountService extends ComponentStore<LibraryCountState> {
  public readonly vm$ = this.select(state => state);

  constructor(
    private libraryService: LibraryService,
    private userService: UserService
  ) {
    super({ totalNumberOfLibraries: 0, totalNumberOfOwnLibraries: 0, totalNumberOfFilteredLibraries: 0 });
  }

  public setTotalNumberOfLibraries(totalNumberOfLibraries: number): void {
    if (totalNumberOfLibraries < 0) {
      throw new Error('LibraryCountService: Total number of libraries must be greater than or equal to 0');
    }

    this.patchState({ totalNumberOfLibraries });
  }

  public loadTotalNumberOfLibraries(): void {
    this.getLibrariesCount().subscribe(totalNumberOfLibraries => this.setTotalNumberOfLibraries(totalNumberOfLibraries));
  }

  public loadTotalNumberOfOwnLibraries(): void {
    this.userService.currentUser$
      .pipe(
        isTruthy(),
        take(1),
        switchMap(currentUser => this.getLibrariesCount({ createdBy: currentUser.oid }))
      )
      .subscribe(totalNumberOfOwnLibraries => {
        this.patchState({ totalNumberOfOwnLibraries });
      });
  }

  private getLibrariesCount(filter?: LibraryFindOptions): Observable<number> {
    return this.libraryService.find({ ...filter, size: 1, page: 0 }).pipe(
      take(1),
      map(result => result.paging.totalElementCount)
    );
  }
}
