<ng-container *ngIf="{ repositories: repositoryOptions$ | async } as data">
  <span
    *ngIf="data.repositories === undefined"
    class="libs-repository-selector_text libs-repository-selector_text--loading"
    data-test-repository-selector-loading-repositories>
    {{ 'LIBRARIES.CREATE.LOADING_REPOSITORIES' | translate }}
  </span>
  <span *ngIf="data.repositories" data-test-repository-selector>
    <celum-select-box
      *ngIf="data.repositories.length > 1"
      data-test-repository-selector-select
      [control]="control"
      [options]="data.repositories"
      [panelClass]="'libs-repository-selector_select-box'"></celum-select-box>
    <span
      *ngIf="data.repositories.length === 0"
      class="libs-repository-selector_text libs-repository-selector_text--no-repositories"
      data-test-repository-selector-no-repository>
      {{ 'LIBRARIES.CREATE.NO_REPOSITORIES' | translate }}
    </span>

    <span *ngIf="data.repositories.length === 1" class="libs-repository-selector_text" data-test-repository-selector-one-repository>
      {{ control.value }}
    </span>
  </span>
</ng-container>
