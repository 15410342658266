import { Observable } from 'rxjs';

export interface Asset {
  filePreviewUrl: Observable<string>;
  fileExtension: string;
  fileName: string;
  canDownloadOriginal?: boolean;
}

export enum RenditionType {
  SMALL = 'small',
  THUMBNAIL = 'thumbnail'
}
