import { CdkStepperModule } from '@angular/cdk/stepper';
import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject, OnInit } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MAT_DIALOG_DATA, MatDialogModule } from '@angular/material/dialog';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { CelumButtonModule, CelumIconModule, CelumLoadingMaskModule } from '@celum/common-components';
import { CelumFullscreenDialogModule, CelumWizardActionsComponent, CelumWizardModule, CelumWizardStepConfiguration } from '@celum/internal-components';
import { Library } from '@celum/libraries/shared';
import { CelumPipesModule } from '@celum/ng2base';
import { DesignerEditMode } from '@celum/shared/domain';
import { DesignerService } from '@celum/shared/util';

import { LibraryAssetsStepComponent } from './components/assets-step/assets-step.component';
import { AssetsStepService } from './components/assets-step/assets-step.service';
import { LibraryDetailsComponent } from './components/library-details/library-details.component';
import { MetadataStepComponent } from './components/metadata-step/metadata-step.component';
import { VariantsStepComponent } from './components/variants-step/variants-step.component';
import { LibraryWizardService } from './library-wizard.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatButtonModule,
    MatProgressSpinnerModule,
    MatDialogModule,
    CdkStepperModule,
    TranslateModule,

    CelumFullscreenDialogModule,
    CelumIconModule,
    CelumPipesModule,
    CelumWizardModule,
    CelumButtonModule,
    CelumLoadingMaskModule,
    CelumWizardActionsComponent,

    LibraryDetailsComponent,
    LibraryAssetsStepComponent,
    MetadataStepComponent,
    VariantsStepComponent
  ],
  selector: 'libs-library-wizard',
  templateUrl: './library-wizard.component.html',
  styleUrls: ['./library-wizard.component.scss'],
  providers: [DesignerService, LibraryWizardService, AssetsStepService],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryWizardComponent implements OnInit {
  public DesignerEditMode = DesignerEditMode;

  public stepsConfiguration: CelumWizardStepConfiguration[] = [
    {
      icon: 'details-m',
      label: 'LIBRARIES.CREATE.DETAILS_STEP'
    },
    {
      icon: 'image-s',
      label: 'LIBRARIES.CREATE.ASSETS_STEP'
    },
    {
      icon: 'file-with-text',
      label: 'LIBRARIES.CREATE.METADATA_STEP'
    },
    {
      icon: 'variants',
      label: 'LIBRARIES.CREATE.VARIANTS_STEP'
    }
  ];

  constructor(
    @Inject(MAT_DIALOG_DATA) protected library: Library,
    protected service: LibraryWizardService
  ) {}

  public ngOnInit(): void {
    this.service.initializeLibraryDesignerService(this.library);
  }

  protected trackByIdxFn(index: number): number {
    return index;
  }
}
