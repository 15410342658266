export enum SelectionBehavior {
  /** Allow only single selection - every click changes the selected item. */
  SINGLE = 'single',
  /** Allow multi selection when using CTRL or SHIFT keys. */
  MULTI_KEY = 'multi-key',
  /** Multi selection is enabled by default. Every click _adds_ or _removes_ the item. */
  MULTI_TOGGLE = 'multi-toggle',
  /** MULTI_TOGGLE, but with a maximum selectable amount of items. */
  MULTI_TOGGLE_LIMITED = 'multi-toggle-limited'
}
