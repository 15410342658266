import { Directionality } from '@angular/cdk/bidi';
import {
  Overlay,
  OverlayConfig,
  OverlayContainer,
  OverlayKeyboardDispatcher,
  OverlayOutsideClickDispatcher,
  OverlayPositionBuilder,
  OverlayRef,
  ScrollStrategyOptions
} from '@angular/cdk/overlay';
import { DOCUMENT, Location } from '@angular/common';
import { ANIMATION_MODULE_TYPE, ComponentFactoryResolver, Inject, Injectable, InjectionToken, Injector, NgZone, Optional, Provider } from '@angular/core';

export const OVERLAY_CLASS = new InjectionToken<string>('OverlayClass');

// Use this class to add a class specified by OVERLAY_CLASS token to the overlay root element. Although material modules, which use the overlay ( e.g. ToolTipModule )
// also offer you the opportunity to add custom classes, they are always added either to the panel or a specific element inside the overlay panel.
// e.g. tooltip adds the custom class defined by matTooltipClass property to the first div inside mat-tooltip-component
// In some cases you might want to add custom styling a few levels above where the custom class was added, previously you could only do this by defining the style globally,
// which affected other overlays of the application.
// In other cases you might want to use the same style for multiple components and want to avoid importing it in every affected component
// You can use CustomOverlay to apply styles only for your overlay and avoid affecting other overlays.
// Usage example:
// providers: [
//  { provide: OVERLAY_CLASS, useValue: 'portal-theme' },
//  { provide: Overlay, useClass: CustomOverlay },
//  OverlayContainer    <--- you want to use a new overlay container, so your custom classes are not added to the existing container
// ]
@Injectable({ providedIn: 'root' })
export class CustomOverlay extends Overlay {
  constructor(
    scrollStrategies: ScrollStrategyOptions,
    overlayContainer: OverlayContainer,
    componentFactoryResolver: ComponentFactoryResolver,
    positionBuilder: OverlayPositionBuilder,
    keyboardDispatcher: OverlayKeyboardDispatcher,
    injector: Injector,
    ngZone: NgZone,
    @Inject(DOCUMENT) document: any,
    directionality: Directionality,
    location: Location,
    outsideClickDispatcher: OverlayOutsideClickDispatcher,
    @Inject(ANIMATION_MODULE_TYPE) @Optional() animationsModuleType?: string,
    @Inject(OVERLAY_CLASS) @Optional() private overlayClass?: string
  ) {
    super(
      scrollStrategies,
      overlayContainer,
      componentFactoryResolver,
      positionBuilder,
      keyboardDispatcher,
      injector,
      ngZone,
      document,
      directionality,
      location,
      outsideClickDispatcher,
      animationsModuleType
    );
  }

  public create(config?: OverlayConfig): OverlayRef {
    const overlayRef = super.create(config);
    overlayRef.overlayElement.parentElement.parentElement.classList.add(this.overlayClass);
    return overlayRef;
  }
}

export function provideCustomOverlay(overlayClass: string): Provider[] {
  return [{ provide: OVERLAY_CLASS, useValue: overlayClass }, { provide: Overlay, useClass: CustomOverlay }, OverlayContainer];
}
