<celum-dialog [hideCloseAllBtn]="true">
  <celum-dialog-header class="contact-dialog_header">
    <span [innerHTML]="config?.headerText | translate"></span>
  </celum-dialog-header>

  <div class="contact-dialog_content">
    <ng-container *ngIf="config.user; else loading">
      <celum-user-avatar
        class="contact-dialog_content_avatar"
        [accountAccessToken]="(selectedAccount$ | async).accountAccessToken"
        [showTooltip]="false"
        [size]="AVATAR_SIZE.xxxl"
        [user]="config.user"></celum-user-avatar>
      <div class="contact-dialog_contact-data">
        <div class="contact-dialog_contact-data_name">{{ config.user.firstName }} {{ config.user.lastName }}</div>
        <a class="contact-dialog_contact-data_email" href="mailto:{{ config.user.email }}">{{ config.user.email }}</a>
      </div>

      <div class="contact-dialog_content_text">
        {{ config?.text | translate: { firstName: config.user.firstName, lastName: config.user.lastName } }}
      </div>
    </ng-container>

    <ng-template #loading>
      <div class="contact-dialog_content_loading">
        <mat-spinner [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
      </div>
    </ng-template>
  </div>

  <celum-dialog-footer
    [confirmBtnText]="config?.confirmButtonText"
    [valid]="!!config.user"
    (onCancel)="dialogRef.close(false)"
    (onConfirm)="contact(config.user.email)"></celum-dialog-footer>
</celum-dialog>
