/**
 * All possible file category types in the system.
 */
export enum FileCategory {
  UNKNOWN = 0,
  IMAGE = 1,
  DOCUMENT = 2,
  VIDEO = 3,
  AUDIO = 4,
  TEXT = 5,
  MODEL3D = 6,
  PLACEHOLDER = 7
}

/**
 * Utility to get the enum value for a given file category name - if available.
 * @category Util
 */
export class FileCategoryUtil {
  public static fromString(category: 'UNKNOWN' | 'IMAGE' | 'DOCUMENT' | 'AUDIO' | 'TEXT' | 'VIDEO' | 'MODEL3D' | 'PLACEHOLDER'): FileCategory {
    return category === 'UNKNOWN'
      ? FileCategory.UNKNOWN
      : category === 'IMAGE'
      ? FileCategory.IMAGE
      : category === 'DOCUMENT'
      ? FileCategory.DOCUMENT
      : category === 'AUDIO'
      ? FileCategory.AUDIO
      : category === 'VIDEO'
      ? FileCategory.VIDEO
      : category === 'TEXT'
      ? FileCategory.TEXT
      : category === 'PLACEHOLDER'
      ? FileCategory.PLACEHOLDER
      : category === 'MODEL3D'
      ? FileCategory.MODEL3D
      : undefined;
  }
}
