<ng-container *ngIf="service.vm$ | async as data">
  <celum-wizard
    #wizard
    data-test-designer-wizard
    [headerText]="data.designerEditMode === DesignerEditMode.CREATE ? 'LIBRARIES.CREATE.NEW_LIBRARY' : 'LIBRARIES.CREATE.EDIT_LIBRARY'"
    [light]="true"
    [linear]="true"
    [showCloseButton]="true"
    [stepsConfiguration]="stepsConfiguration"
    (onClose)="service.closeDialog()"
    (selectionChange)="service.changeStep($event.selectedIndex)">
    <celum-wizard-actions
      celum-wizard-header-actions
      [backButtonVisible]="data.backButton.visible"
      [isCreate]="data.designerEditMode === DesignerEditMode.CREATE"
      [loading]="data.loading"
      [nextButtonDisabled]="!data.nextButton.enabled"
      [nextButtonVisible]="data.nextButton.visible"
      [submitButtonDisabled]="!data.completeButton.enabled"
      (backClicked)="wizard.previous()"
      (nextClicked)="wizard.next()"
      (submitClicked)="service.upsertLibrary()"></celum-wizard-actions>

    <cdk-step *ngFor="let step of data.stepStatus; let index = index; trackBy: trackByIdxFn" [completed]="step.completed" [optional]="step.optional">
      <libs-library-details
        *ngIf="wizard.selectedIndex === 0 && index === 0"
        data-test-details
        [form]="service.libraryForm.controls.details"
        [creator]="data.creator$ | async"
        [usageStatistics]="data.statistics$ | async"></libs-library-details>
      <libs-assets-step
        *ngIf="wizard.selectedIndex === 1 && index === 1"
        data-test-assets
        [form]="service.libraryForm.controls.filters"
        [libraryError]="library?.error"
        [repositoryId]="service.libraryForm.controls.details.controls.repositoryId.value"></libs-assets-step>
      <libs-metadata-step
        *ngIf="wizard.selectedIndex === 2 && index === 2"
        data-test-metadata
        [form]="service.libraryForm.controls.metadata"
        [repositoryId]="service.libraryForm.controls.details.controls.repositoryId.value"></libs-metadata-step>
      <libs-variants-step
        *ngIf="wizard.selectedIndex === 3 && index === 3"
        data-test-variants
        [form]="service.libraryForm.controls.variants"></libs-variants-step>
    </cdk-step>
  </celum-wizard>

  <loading-mask *ngIf="data.loading"></loading-mask>
</ng-container>
