import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { combineLatest, debounceTime, distinctUntilChanged, map, Observable, of, switchMap, tap } from 'rxjs';

import { ColorConstants, IconConfiguration } from '@celum/common-components';
import { tapAllResponses } from '@celum/core';
import { AssetFilter, AssetFilterDto, LibrariesProperties, LibraryError, LibraryErrorKey } from '@celum/libraries/domain';
import { FilterState } from '@celum/shared/domain';
import { ErrorService } from '@celum/shared/util';

export type AssetFilterState = {
  error: LibraryError;
  repositoryId: string;
} & FilterState<AssetFilter>;

@Injectable({ providedIn: 'root' })
export class AssetsFilterService extends ComponentStore<AssetFilterState> {
  public vm$ = this.select(state => this.createViewModel(state));

  public menuOpened = this.effect<boolean>(menuOpen$ =>
    menuOpen$.pipe(
      tap(value => this.patchState({ loading: true, isOpen: value })),
      switchMap(isMenuOpen => {
        if (isMenuOpen === null) {
          return of([]);
        }
        if (isMenuOpen === false) {
          return of(null);
        }

        this.patchState({ searchValue: '' });

        return combineLatest(
          [this.getStoredSearches(this.get().repositoryId).pipe(distinctUntilChanged()), this.searchValue$.pipe(debounceTime(300))],
          (filters: AssetFilter[], searchValue: string) =>
            searchValue ? filters.filter(filter => filter.name.toLowerCase().includes(searchValue.toLowerCase())) : filters
        );
      }),
      tapAllResponses(
        (filters: AssetFilter[]) => {
          this.patchState({ loading: false });

          if (filters) {
            this.patchState({ filteredValues: filters });
          }
        },
        (error: any) => {
          this.errorService.error(
            `AssetsFilterComponent_${new Date().toISOString()}`,
            `LIBRARIES.CREATE.ASSETS.LOAD_FILTERS_ERROR.${error?.error?.errorKey}`,
            error,
            true,
            true
          );
          this.patchState({ loading: false, filteredValues: [] });
        }
      )
    )
  );

  private searchValue$ = this.select(state => state.searchValue);

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {
    super({
      selectedValues: [],
      searchValue: '',
      loading: false,
      filteredValues: [],
      isOpen: false,
      error: null,
      repositoryId: null,
      onTouched: null,
      onChange: null
    });
  }

  public itemSelectionChanged(selectedValues: AssetFilter[]): void {
    const error = this.get().error?.key === LibraryErrorKey.STORED_SEARCH_NOT_FOUND ? null : this.get().error;
    this.patchState({ selectedValues, isOpen: false, error });
    this.get().onChange?.(selectedValues[0]);
  }

  public getStoredSearches(repositoryId: string): Observable<AssetFilter[]> {
    return this.http.get<AssetFilterDto[]>(`${LibrariesProperties.properties.apiUrl}/content-hub/saved-searches/${encodeURI(repositoryId)}`).pipe(
      map(filters =>
        filters.map(filter => ({
          id: filter.externalId,
          name: filter.name
        }))
      )
    );
  }

  private createViewModel(state: AssetFilterState): AssetFilterState & { icons: { [key: string]: IconConfiguration } } {
    return {
      ...state,
      icons: {
        addNewFilter: IconConfiguration.large('add-new-filter')
          .withIconSize(32)
          .withColor(ColorConstants.BLUE_GRAY_600)
          .withHoverColor(ColorConstants.BLUE_GRAY_700),
        search: IconConfiguration.small('search-m').withColor(ColorConstants.BLUE_GRAY_900),
        clearSearch: IconConfiguration.small('cancel-m').withColor(ColorConstants.BLUE_GRAY_900),
        chipFilter: IconConfiguration.small('filter-m').withColor(state.error && ColorConstants.SYSTEM_RED),
        chipClear: IconConfiguration.small('cancel-s')
      }
    };
  }
}
