import { AssetFilter } from '../asset/asset-filter.entity';
import { AssetMetadataField } from '../asset/asset-metadata-field.entity';

export enum LibrarySyncDirection {
  UP = 'UP',
  DOWN = 'DOWN',
  TWO_WAY = 'TWO_WAY'
}

export enum LibrarySyncTrigger {
  AUTOMATIC = 'AUTOMATIC',
  ON_DEMAND = 'ON_DEMAND'
}

export interface LibrarySyncSource {
  assetRepositoryId: string;
  downSyncFilter: AssetFilter;
  infoFieldMapping: AssetMetadataField[];
}

export interface LibrarySyncDefinition {
  syncDirection: LibrarySyncDirection;
  syncTrigger: LibrarySyncTrigger;
  syncSource: LibrarySyncSource;
}
