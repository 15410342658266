import { AbstractControl, ValidationErrors } from '@angular/forms';

import { DataUtil } from '@celum/core';

export function doubleValidator(control: AbstractControl): ValidationErrors | null {
  if (DataUtil.isEmpty(control.value)) {
    return null;
  }

  const reg = /^[-+]?[0-9]+[.]?[0-9]*([eE][-+]?[0-9]+)?$/.exec(control.value);

  if (DataUtil.isEmpty(reg)) {
    return { pattern: { valid: false } };
  }

  return null;
}
