import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';

import { AssetMetadataField, LibrariesProperties } from '@celum/libraries/domain';

@Injectable()
export class MetadataStepService {
  constructor(private readonly http: HttpClient) {}

  public loadAvailableMetadata(repositoryId: string): Observable<AssetMetadataField[]> {
    return this.http.get<AssetMetadataField[]>(`${LibrariesProperties.properties.apiUrl}/content-hub/information-fields/${encodeURI(repositoryId)}`).pipe(
      map((metadata: AssetMetadataField[]) =>
        metadata.map((availableMetadata: AssetMetadataField) => ({
          ...availableMetadata,
          id: availableMetadata.id.toString()
        }))
      )
    );
  }
}
