<ng-container *ngIf="vm$ | async as vm">
  <div class="library-designer_header">
    {{ 'LIBRARIES.CREATE.METADATA.HEADER' | translate }}
    <celum-icon matTooltip="{{ 'LIBRARIES.CREATE.METADATA.METADATA_HEADER_TOOLTIP' | translate }}" [clickable]="false" [configuration]="infoIcon"></celum-icon>
  </div>
  <div class="libs-metadata-step_number">
    <span>{{ form.controls.metadata.value?.length }}</span>
    {{
      (form.controls.metadata.value?.length === 1 ? 'LIBRARIES.CREATE.METADATA.SINGLE_METADATA_FIELD' : 'LIBRARIES.CREATE.METADATA.MULTIPLE_METADATA_FIELDS')
        | translate
    }}
  </div>
  <libs-metadata-filter [error]="vm.error" [formControl]="form.controls.metadata" [repositoryId]="repositoryId"></libs-metadata-filter>
  <div *ngIf="vm.loading" class="libs-metadata-step_loading">
    <mat-spinner [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
  </div>
  <libs-metadata-list
    *ngIf="!vm.loading"
    data-test-library-metadata-metadata-list
    [metadata]="form.controls.metadata.value"
    (removeItem)="removeItem($event)"></libs-metadata-list>
</ng-container>
