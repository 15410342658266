import { schema } from 'normalizr';

import { AvatarConfiguration } from '@celum/common-components';
import { LibraryDto, LibraryError, LibraryStatus, LibrarySyncDefinition } from '@celum/libraries/domain';
import { Entity, EntityRegistry, EntityTranslator, relatedEntity, RelationFunction } from '@celum/ng2base';
import { AccountUser } from '@celum/shared/domain';
import { AccountUserService } from '@celum/shared/shared';

import { LibraryUsageStatisticsService } from './services/library-usage-statistics.service';
import { UsageStatistics } from './usage-statistics.entity';

export const LIBRARY = `Library`;

export interface Library extends Entity {
  name: string;
  avatars: AvatarConfiguration[];
  status: LibraryStatus;
  syncDefinition: LibrarySyncDefinition;
  hidden: boolean;
  readonly: boolean;
  modifiedAt: Date;
  createdAt: Date;
  createdById: string;
  createdBy: RelationFunction<AccountUser>;
  variantTypeIds: string[];
  usageStatisticsId?: string;
  usageStatistics?: RelationFunction<UsageStatistics>;
  error: LibraryError;
}

const libraryTranslator: EntityTranslator<Library> = (libraryDto: LibraryDto) => ({
  id: libraryDto.id,
  typeKey: LIBRARY,
  name: libraryDto.name,
  avatars: [],
  status: libraryDto.status,
  syncDefinition: {
    syncDirection: libraryDto.syncDefinition.syncDirection,
    syncSource: {
      assetRepositoryId: libraryDto.syncDefinition.syncSource.assetRepositoryId,
      downSyncFilter: {
        id: libraryDto.syncDefinition.syncSource.downSyncFilter.externalId,
        name: libraryDto.syncDefinition.syncSource.downSyncFilter.name
      },
      infoFieldMapping: libraryDto.syncDefinition.syncSource.infoFieldMapping.map(dto => ({
        id: dto.externalId,
        name: dto.name,
        type: dto.type,
        labels: dto.labels
      }))
    },
    syncTrigger: libraryDto.syncDefinition.syncTrigger
  },
  hidden: libraryDto.hidden,
  readonly: libraryDto.readonly,
  createdAt: new Date(libraryDto.createdAt),
  modifiedAt: new Date(libraryDto.modifiedAt),
  variantTypeIds: libraryDto.variantTypes,
  createdById: libraryDto.createdBy,
  createdBy: relatedEntity<AccountUser>(libraryDto.id, 'createdById'),
  usageStatisticsId: `${libraryDto.id}_usage_statistics`,
  usageStatistics: relatedEntity<UsageStatistics>(libraryDto.id, 'usageStatisticsId'),
  error: libraryDto.error
});

const librarySchema = new schema.Entity<Library>(LIBRARY);

EntityRegistry.register<Library>({
  typeKey: LIBRARY,
  translator: libraryTranslator,
  schema: librarySchema,
  resolveStrategy: { createdById: AccountUserService, usageStatisticsId: LibraryUsageStatisticsService }
});
