<celum-standard-dialog class="message-dialog"
                       [buttons]="config?.buttons"
                       [cancelBtnText]="config?.cancelButtonText"
                       [color]="color"
                       [confirmBtnText]="config?.okButtonText"
                       [disableBackdropClose]="config?.disableBackdropClose"
                       [disableOnCancelClosing]="config?.disableOnCancelClosing"
                       [disableOnConfirmClosing]="config?.disableOnConfirmClosing"
                       [hideCloseAllBtn]="config?.hideCloseAllBtn"
                       [loading]="config?.loading"
                       [valid]="valid"
                       (onConfirm)="onConfirmClick()"
                       (onCancel)="onCancelClick()"
                       (onCloseAll)="onCancelClick()">
  <div class="message-dialog_content">
    <message [configuration]="messageConfig"></message>
    <ng-content></ng-content>
  </div>
</celum-standard-dialog>
