<div class="assets_wrapper" *ngIf="service.vm$ | async as vm">
  <div class="library-designer_header">
    {{ 'LIBRARIES.CREATE.ASSETS.FILTERS_HEADER' | translate }}
    <celum-icon [configuration]="infoIcon" [clickable]="false" matTooltip="{{ 'LIBRARIES.CREATE.ASSETS.FILTERS_HEADER_TOOLTIP' | translate }}"></celum-icon>
  </div>

  <libs-assets-filter [error]="vm.error" [formControl]="form.controls.filter" [repositoryId]="repositoryId"></libs-assets-filter>

  <div class="library-designer_header">{{ 'LIBRARIES.CREATE.ASSETS.ASSETS_HEADER' | translate }}</div>

  <div class="assets_preview-section">
    <div class="assets_preview-count">
      <span>{{ vm.totalElementCount }}&nbsp;</span>
      {{ (vm.totalElementCount === 1 ? 'LIBRARIES.CREATE.ASSETS.SINGLE_ASSET' : 'LIBRARIES.CREATE.ASSETS.MULTIPLE_ASSETS') | translate }}
    </div>

    <message-box
      *ngIf="vm.assets.length > 0"
      class="assets_preview-message-box"
      data-test-asset-step-unavailable-assets-info
      [icon]="'attention-circle-m'"
      [text]="'LIBRARIES.CREATE.ASSETS.UNAVAILABLE_ASSETS'"
      [type]="'info'"></message-box>

    <libs-assets-list
      data-test-library-assets-asset-list
      [assets]="vm.assets"
      [emptyPageConfig]="form.controls.filter.value ? noAssetsFoundFiltered : noAssetsNoFilterApplied"
      [loading]="vm.loadingAssets"
      (lastItemIsVisible)="$event && vm.hasBottom && service.loadNextBatch()"></libs-assets-list>
  </div>
</div>
