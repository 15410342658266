import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatTooltipModule } from '@angular/material/tooltip';

import { LookupArea } from './component/lookup-area';
import { CelumIconModule } from '../icon/index';
import { CelumInputsModule } from '../inputs/index';

@NgModule({
            imports: [
              CommonModule, ReactiveFormsModule,
              MatFormFieldModule, MatInputModule, MatTooltipModule,
              CelumIconModule, CelumInputsModule
            ],
            declarations: [
              LookupArea
            ],
            exports: [
              LookupArea, CelumIconModule, CelumInputsModule, MatFormFieldModule, MatInputModule, MatTooltipModule
            ]
          })
export class CelumLookupAreaModule {
}
