<ng-container *ngIf="vm$ | async as vm">
  <message-box
    *ngIf="vm.storageStatus !== StorageLimitStatus.OK"
    class="libs-storage-limit-message-box"
    data-test-dashboard-storage-message
    [icon]="'attention-m'"
    [text]="vm.storageStatus === StorageLimitStatus.THRESHOLD_LIMIT_REACHED ? 'LIBRARIES.STORAGE.RUNNING_OUT' : 'LIBRARIES.STORAGE.REACHED'"
    [type]="'warn'">
    <a *ngIf="vm.isOwner" class="libs-storage-limit-message-box_action" messageText [href]="upgradeStorageUrl$ | async">
      {{ 'LIBRARIES.STORAGE.ACTIONS.UPGRADE' | translate | uppercase }}
    </a>
    <button *ngIf="!vm.isOwner" class="libs-storage-limit-message-box_action" messageText (click)="upgradePlan(vm.owner)">
      {{ 'LIBRARIES.STORAGE.ACTIONS.INFORM_OWNER' | translate | uppercase }}
    </button>
  </message-box>
</ng-container>
