import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS } from '@angular/forms';

export function pureNumber(c: AbstractControl): any {
  let validationResult = true;

  if (c.value !== undefined && c.value !== null) {
    validationResult = /^\s*-?\s*[0-9]+$/.test((c.value + '').trim());
  }

  return (validationResult) ? null : {
    pureNumber: {
      valid: false,
      messageKey: 'VALIDATION.PURE_NUMBER'
    }
  };
}

@Directive({
             selector: '[pureNumber][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: pureNumber,
                 multi: false
               }
             ]
           })
export class PureNumberValidator {

}
