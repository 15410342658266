import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';

import { EntityEffects } from '../entity-store/entity-effects';
import { entityReducer } from '../entity-store/entity-reducer';
import { CELUM_ENTITY_SLICE_NAME } from '../entity-store/entity-state';

/**
 * Module that provides all functionality exposed by the ng2base entity package.
 * Using this module requires imports for `StoreModule.forRoot(...)` and `EffectsModule.forRoot(...)` on a parent module.
 */
@NgModule({
            imports: [
              StoreModule.forFeature(CELUM_ENTITY_SLICE_NAME, entityReducer),
              EffectsModule.forFeature([EntityEffects])
            ]
          })
export class CelumEntityModule {

}
