<ng-container *ngIf="vm$ | async as vm">
  <div class="library-designer_header">
    {{ 'LIBRARIES.CREATE.VARIANTS.HEADER' | translate }}
    <celum-icon matTooltip="{{ 'LIBRARIES.CREATE.VARIANTS.VARIANTS_HEADER_TOOLTIP' | translate }}" [clickable]="false" [configuration]="infoIcon"></celum-icon>
  </div>
  <div class="libs-variants-step_number">
    <span>{{ vm.selectedVariants.length }}</span>
    {{
      (vm.selectedVariants.length === 1 ? 'LIBRARIES.CREATE.VARIANTS.SINGLE_VARIANT_FIELD' : 'LIBRARIES.CREATE.VARIANTS.MULTIPLE_VARIANTS_FIELDS') | translate
    }}
  </div>

  <libs-variants-filter [formControl]="form.controls.variants"></libs-variants-filter>
  <libs-variants-list data-test-library-variants-variants-list [variants]="vm.selectedVariants" (removeItem)="removeItem($event)"></libs-variants-list>
</ng-container>
