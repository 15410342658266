import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { FormBuilder, FormControl, FormGroup } from '@angular/forms';
import { ComponentStore } from '@ngrx/component-store';
import { Store } from '@ngrx/store';
import { catchError, combineLatest, EMPTY, map, Observable, of, take, takeUntil } from 'rxjs';

import { ShowSnackbar, SimpleSnackbar, SnackbarConfiguration } from '@celum/common-components';
import { isArrayEqual, isEqualSimpleObject, UUIDGenerator } from '@celum/core';
import { AssetFilter, AssetMetadataField, LibrariesProperties, LibraryVariant } from '@celum/libraries/domain';
import { Library, LibraryCreationRequestType, LibraryService, UpdateRequestType } from '@celum/libraries/shared';
import { CelumValidators } from '@celum/ng2base';
import { AccountUser, DesignerEditMode, DesignerServiceViewModel } from '@celum/shared/domain';
import { DesignerService, ErrorService } from '@celum/shared/util';

import { variantTypes } from './components/variants-step/variant-types.const';

export interface LibraryDesignerServiceState {
  loading: boolean;
  library: Library;
}

export interface LibraryDesignerServiceViewModel extends DesignerServiceViewModel {
  loading: boolean;
  library: Library;
  creator$: Observable<AccountUser>;
  statistics$: Observable<any>;
}

export interface ContentHubRepository {
  repositoryId: string;
}

interface ContentHubRepositories {
  repositories: ContentHubRepository[];
}

export enum ContentHubRepositoryFeature {
  IMPORT_SAVED_SEARCH = 'import-saved-search',
  INFORMATION_FIELDS = 'information-fields'
}

const LIBRARY_WIZARD_SERVICE = 'LibraryWizardService';

@Injectable()
export class LibraryWizardService extends ComponentStore<LibraryDesignerServiceState> {
  public vm$ = combineLatest([this.state$, this.designerService.vm$]).pipe(
    map(([state, designerServiceViewModel]) => this.createViewModel(state, designerServiceViewModel))
  );

  public libraryForm: FormGroup<{
    details: FormGroup<{
      name: FormControl<string>;
      repositoryId: FormControl<string>;
    }>;
    filters: FormGroup<{
      filter: FormControl<AssetFilter>;
    }>;
    metadata: FormGroup<{
      metadata: FormControl<AssetMetadataField[]>;
    }>;
    variants: FormGroup<{
      variants: FormControl<LibraryVariant[]>;
    }>;
  }>;

  constructor(
    private httpClient: HttpClient,
    protected formBuilder: FormBuilder,
    protected designerService: DesignerService,
    private libraryService: LibraryService,
    private errorService: ErrorService,
    protected store: Store<any>
  ) {
    super({
      loading: false,
      library: null
    });
  }

  public initializeLibraryDesignerService(library?: Library): void {
    const designerEditMode = library ? DesignerEditMode.EDIT : DesignerEditMode.CREATE;

    this.libraryForm = this.formBuilder.group({
      details: this.formBuilder.nonNullable.group(
        {
          name: this.formBuilder.control(library?.name || '', CelumValidators.required),
          repositoryId: this.formBuilder.control(
            {
              value: library?.syncDefinition.syncSource.assetRepositoryId || '',
              disabled: designerEditMode === DesignerEditMode.EDIT
            },
            CelumValidators.required
          )
        },
        { validators: CelumValidators.required }
      ),
      filters: this.formBuilder.nonNullable.group(
        {
          filter: this.formBuilder.control<AssetFilter>(library?.syncDefinition.syncSource.downSyncFilter, CelumValidators.required)
        },
        { validators: CelumValidators.required }
      ),
      metadata: this.formBuilder.nonNullable.group({
        metadata: this.formBuilder.control<AssetMetadataField[]>(library?.syncDefinition.syncSource.infoFieldMapping)
      }),
      variants: this.formBuilder.nonNullable.group({
        variants: this.formBuilder.control<LibraryVariant[]>(
          library?.variantTypeIds?.map(variantId => variantTypes.find(variantType => variantType.id === variantId)) || []
        )
      })
    });

    this.patchState({ library });

    this.libraryForm.controls.details.controls.repositoryId.valueChanges.pipe(takeUntil(this.destroy$)).subscribe(() => {
      this.libraryForm.controls.filters.controls.filter.setValue(null);
      this.libraryForm.controls.metadata.controls.metadata.setValue([]);
      this.libraryForm.controls.variants.controls.variants.setValue([]);
    });

    this.designerService.initializeDesigner(this.libraryForm, designerEditMode, this.compareFormObjects);
  }

  public getRepositoriesSupportingFeatures(features: ContentHubRepositoryFeature[]): Observable<ContentHubRepository[]> {
    // TODO: Handle cases where the user has no license for CH or no repositories assigned: https://celum.atlassian.net/browse/SLIB-193
    return this.httpClient.get<ContentHubRepositories>(`${LibrariesProperties.properties.apiUrl}/repositories/content-hub?features=${features}`).pipe(
      map(repositoryResponse => repositoryResponse.repositories),
      catchError(error => {
        console.error('LibraryDesignerService: Error while fetching repositories with required feature', error);
        return of([]);
      })
    );
  }

  public upsertLibrary(): void {
    this.designerService.vm$.pipe(take(1)).subscribe((data: DesignerServiceViewModel) => {
      if (this.get().loading || this.designerService.form.invalid) {
        return;
      }
      this.get().loading = true;

      if (data.designerEditMode === DesignerEditMode.CREATE) {
        this.createLibrary();
      } else {
        this.updateLibrary();
      }
    });
  }

  public createLibrary(): void {
    this.libraryService
      .create({
        assetRepositoryId: this.libraryForm.value.details.repositoryId,
        downSyncFilter: {
          externalId: this.libraryForm.value.filters.filter.id,
          name: this.libraryForm.value.filters.filter.name
        },
        name: this.libraryForm.value.details.name,
        creationRequestType: LibraryCreationRequestType.CONNECTED,
        infoFieldMapping: this.libraryForm.value.metadata.metadata?.map(field => ({
          externalId: field.id,
          name: field.name,
          type: field.type,
          labels: field.labels
        })),
        variantTypes: this.libraryForm.value.variants.variants?.map(variant => variant.id)
      })
      .pipe(
        take(1),
        catchError((error: HttpErrorResponse) => {
          if ([401, 403].includes(error?.status)) {
            this.errorService.error(LIBRARY_WIZARD_SERVICE, 'LIBRARIES.ERROR.PERMISSION_CREATE', error.error);
          } else {
            this.errorService.httpError(error, LIBRARY_WIZARD_SERVICE);
          }
          this.closeDialog();
          return EMPTY;
        })
      )
      .subscribe(() => {
        const successSnackbar = new ShowSnackbar(
          UUIDGenerator.generateId(),
          SimpleSnackbar,
          SnackbarConfiguration.success('LIBRARIES.CREATE.SNACKBAR.SUCCESS.HEADER').withDescription('LIBRARIES.CREATE.SNACKBAR.SUCCESS.TEXT')
        );
        this.store.dispatch(successSnackbar);
        this.closeDialog(true);
      });
  }

  public updateLibrary(): void {
    this.libraryService
      .update(this.get().library.id, {
        downSyncFilter: {
          externalId: this.libraryForm.value.filters.filter.id,
          name: this.libraryForm.value.filters.filter.name
        },
        name: this.libraryForm.value.details.name,
        infoFieldMapping: this.libraryForm.value.metadata.metadata?.map(field => ({
          externalId: field.id,
          name: field.name,
          type: field.type,
          labels: field.labels
        })),
        variantTypes: this.libraryForm.value.variants.variants?.map(variant => variant.id),
        updateRequestType: UpdateRequestType.allMutable
      })
      .subscribe({
        next: () => this.closeDialog(true),
        error: error => {
          this.get().loading = false;
          if ([401, 403].includes(error?.status)) {
            this.errorService.error('LibraryWizardService', `LIBRARIES.ERROR.PERMISSION_EDIT`, error.error);
          } else {
            this.errorService.error('LibraryWizardService', 'LIBRARIES.CREATE.SNACKBAR.ERROR.HEADER', error);
          }
        }
      });
  }

  public closeDialog(result = false): void {
    this.designerService.closeDialog(result);
  }

  public changeStep(selectedIndex: number): void {
    this.designerService.changeStep(selectedIndex);
  }

  // requires own compare function as isEqualSimpleObject can't correctly handle object which contains array of objects
  private compareFormObjects(library1: any, library2: any): boolean {
    return (
      isEqualSimpleObject(library1.details.name, library2.details.name) &&
      isEqualSimpleObject(library1.details.repositoryId, library2.details.repositoryId) &&
      isEqualSimpleObject(library1.filters.filter, library2.filters.filter) &&
      isArrayEqual(
        library1.metadata.metadata?.map((data: any) => data.id),
        library2.metadata.metadata?.map((data: any) => data.id)
      ) &&
      isArrayEqual(
        library1.variants.variants?.map((data: any) => data.id),
        library2.variants.variants?.map((data: any) => data.id)
      )
    );
  }

  private createViewModel(state: LibraryDesignerServiceState, designerServiceViewModel: DesignerServiceViewModel): LibraryDesignerServiceViewModel {
    return {
      ...designerServiceViewModel,
      loading: state.loading,
      creator$: state.library?.createdBy(this.store),
      library: state.library,
      statistics$: state.library?.usageStatistics(this.store)
    };
  }
}
