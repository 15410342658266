import { NgModule } from '@angular/core';

import { DropzoneDirective } from './components/dropzone/dropzone';

@NgModule({
            declarations: [DropzoneDirective],
            exports: [DropzoneDirective]
          })
export class CelumDragAndDropModule {
}
