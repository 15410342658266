import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';

import { LoadingMask } from './component/loading-mask';

@NgModule({
            imports: [
              CommonModule, MatProgressSpinnerModule
            ],
            declarations: [
              LoadingMask
            ],
            exports: [
              LoadingMask
            ]
          })
export class CelumLoadingMaskModule {
}
