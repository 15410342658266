<div class="empty-page" [attr.data-test-empty-page]="config?.id" [style.max-width]="config?.maxWidth">
  <celum-icon class="empty-page_image-icon" [configuration]="config?.iconConfiguration" [clickable]="false"></celum-icon>
  <div
    class="empty-page_message"
    [class.empty-page_message--small]="config?.textSize === 'small'"
    [class.empty-page_message--normal]="config?.textSize === 'normal'">
    {{config?.message | translate}}
  </div>
  <button
    *ngIf="config?.actionVisible && config?.actionType === 'button'"
    class="empty-page_action-btn"
    [attr.data-empty-page-action]="config?.id"
    celum-button
    mat-flat-button
    size="small"
    [style.background]="'var(--colorPrimary)'"
    (click)="actionBtnClicked()">
    <celum-icon *ngIf="config?.actionIcon" class="empty-page_action-icon" [configuration]="config?.actionIcon"></celum-icon>
    {{config?.actionText | translate | uppercase}}
  </button>
  <div *ngIf="config?.actionVisible && config?.actionType === 'link'" class="empty-page_action-link" (click)="actionBtnClicked()">
    <span class="empty-page_action-link-text">{{config?.actionText | translate}}</span>
  </div>
</div>
