import { OverlayModule } from '@angular/cdk/overlay';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CelumDirectivesModule } from '@celum/ng2base';

import { CelumAvatarList } from './component/avatar-list';
import { AvatarListMenu } from './component/avatar-list-menu';
import { CelumAvatarModule } from '../avatar/avatar.module';

@NgModule({
            imports: [
              CommonModule,
              CelumAvatarModule,
              CelumDirectivesModule,
              TranslateModule,
              OverlayModule
            ],
            declarations: [CelumAvatarList, AvatarListMenu],
            exports: [CelumAvatarList, TranslateModule]
          })
export class CelumAvatarListModule {
}
