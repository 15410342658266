import { Injectable } from '@angular/core';
import { ActivatedRoute, NavigationEnd, Params, Router } from '@angular/router';
import { filter, take } from 'rxjs/operators';

@Injectable({ providedIn: 'root' })
export class RouteParamService {

  constructor(private router: Router, private route: ActivatedRoute) {
  }

  public setTemporaryParam(param: string, value: any): void {
    this.setParams({ [param]: value });
  }

  public clearParams(params: string[]): void {
    const queryParams: Params = {};
    params.forEach(param => queryParams[param] = null);

    // if we'd navigate directly on page load, the resolved route would be '/' and it would redirect to dashboard if we execute a router.navigate on top of it
    // so we wait for the first navigationEnd which means that the main route is resolved and update the params from there
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      take(1)
    ).subscribe(() => this.setParams(queryParams));
  }

  private setParams(queryParams: Params): void {
    this.router.navigate([], {
      queryParams,
      queryParamsHandling: 'merge',
      relativeTo: this.route
    });
  }
}
