import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatSelectModule } from '@angular/material/select';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule, CelumInputsModule, CelumMessageModule } from '@celum/common-components';
import { CelumDirectivesModule, CelumPipesModule } from '@celum/ng2base';

import { SelectBoxComponent } from './select-box.component';

@NgModule({
            declarations: [SelectBoxComponent],
            imports: [
              CommonModule,
              MatSelectModule,
              MatFormFieldModule,
              MatTooltipModule,
              FormsModule,
              ReactiveFormsModule,
              TranslateModule,

              CelumDirectivesModule,
              CelumIconModule,
              CelumPipesModule,
              CelumInputsModule,
              CelumMessageModule,
            ],
            exports: [SelectBoxComponent]
          })
export class CelumSelectBoxModule {
}
