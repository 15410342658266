import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, RouterStateSnapshot, Routes } from '@angular/router';

import { authGuardWithLicenceCheck, LicenseType } from '@celum/authentication';
import { CelumPropertiesProvider } from '@celum/core';

const routes: Routes = [
  { path: 'logout', loadComponent: () => import('@celum/authentication').then(mod => mod.LogoutComponent) },
  {
    path: '',
    canActivate: [
      (state: RouterStateSnapshot) =>
        authGuardWithLicenceCheck(state, LicenseType.LIBRARIES, CelumPropertiesProvider.properties.authentication.applicationFallbackPagesPerLanguage)
    ],
    children: [
      {
        path: 'dashboard',
        loadComponent: () => import('@celum/libraries/features/dashboard').then(mod => mod.DashboardComponent)
      },
      {
        path: 'preview/:id',
        loadComponent: () => import('@celum/libraries/features/preview').then(mod => mod.LibraryPreviewComponent)
      },
      {
        path: '**',
        redirectTo: 'dashboard'
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })],
  exports: [RouterModule]
})
export class AppRoutingModule {}
