export const variantTypes = [
  {
    id: 'original',
    name: {
      en: 'Original',
      de: 'Original',
      es: 'Original',
      it: 'Originale',
      fr: 'Original'
    }
  },
  {
    id: 'small',
    name: {
      en: 'Small',
      de: 'Klein',
      es: 'Pequeño',
      it: 'Piccolo',
      fr: 'Petit'
    }
  },
  {
    id: 'medium',
    name: {
      en: 'Medium',
      de: 'Mittel',
      es: 'Mediano',
      it: 'Medio',
      fr: 'Moyen'
    }
  },
  {
    id: 'large',
    name: {
      en: 'Large',
      de: 'Groß',
      es: 'Grande',
      it: 'Grande',
      fr: 'Grand'
    }
  },
  {
    id: 'large_webp',
    name: {
      en: 'Large WEBP',
      de: 'Groß WEBP',
      es: 'Grande WEBP',
      it: 'Grande WEBP',
      fr: 'Grand WEBP'
    }
  },
  {
    id: 'video',
    name: {
      en: 'Video',
      de: 'Video',
      es: 'Video',
      it: 'Video',
      fr: 'Video'
    }
  },
  {
    id: 'audio',
    name: {
      en: 'Audio',
      de: 'Audio',
      es: 'Audio',
      it: 'Audio',
      fr: 'Audio'
    }
  },
  {
    id: 'pdf',
    name: {
      en: 'PDF',
      de: 'PDF',
      es: 'PDF',
      it: 'PDF',
      fr: 'PDF'
    }
  },
  {
    id: 'svg',
    name: {
      en: 'SVG',
      de: 'SVG',
      es: 'SVG',
      it: 'SVG',
      fr: 'SVG'
    }
  }
];
