import { CommonModule } from '@angular/common';
import { Component, DestroyRef, HostBinding, inject, OnInit, ViewEncapsulation } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { ControlValueAccessor, FormControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { map, Observable, startWith, tap } from 'rxjs';

import { CelumIconModule } from '@celum/common-components';
import { CelumSelectBoxModule, Option } from '@celum/internal-components';

import { ContentHubRepositoryFeature, LibraryWizardService } from '../../../library-wizard.service';

@Component({
  selector: 'libs-repository-selector',
  templateUrl: './repository-selector.component.html',
  styleUrls: ['./repository-selector.component.scss'],
  imports: [TranslateModule, CelumIconModule, CelumSelectBoxModule, MatTooltipModule, CommonModule],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: RepositorySelectorComponent,
      multi: true
    }
  ],
  standalone: true,
  encapsulation: ViewEncapsulation.None
})
export class RepositorySelectorComponent implements OnInit, ControlValueAccessor {
  public control = new FormControl();

  @HostBinding('class.libs-repository-selector')
  protected repositoryOptions$: Observable<Option[]>;

  private destroyRef = inject(DestroyRef);

  private onChange: (value: string) => void;

  constructor(private libraryDesignerService: LibraryWizardService) {}

  public writeValue(obj: any): void {
    this.control.setValue(obj);
  }

  public registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  public registerOnTouched(fn: any): void {}

  public ngOnInit(): void {
    // This is needed because the celum-select-box component stores selected value in a FormControl
    this.control.valueChanges.pipe(takeUntilDestroyed(this.destroyRef)).subscribe(value => {
      this.onChange?.(value);
    });

    this.repositoryOptions$ = this.libraryDesignerService
      .getRepositoriesSupportingFeatures([ContentHubRepositoryFeature.IMPORT_SAVED_SEARCH, ContentHubRepositoryFeature.INFORMATION_FIELDS])
      .pipe(
        startWith(undefined),
        map(repos => repos?.sort((repoA, repoB) => repoA.repositoryId.localeCompare(repoB.repositoryId))),
        map(repositories => repositories?.map(repository => repository.repositoryId)),
        tap(repositoryIds => repositoryIds && !this.control.value && this.control.setValue(repositoryIds[0]))
      );
  }
}
