import { EventEmitter } from '@angular/core';

import { ListSelectionHandler } from './list-selection-handler.service';
import { SelectionBehavior } from '../model/list-selection-handler.model';

export class ClickHandlerService<E> {
  constructor(
    private selectionHandler: ListSelectionHandler<E>,
    private stopClickEventPropagation: boolean,
    private clearSelectionOnEmptySpaceClick: boolean,
    private doubleClickEmitter: EventEmitter<E>
  ) {}

  public onClick(event: MouseEvent, item: E): void {
    if (!this.selectionHandler) {
      return;
    }

    // event will bubble up to "empty-space" and should be ignored there so we mark the event
    ClickHandlerService.markEventAsHandled(event);

    if (event.button === 2) {
      // necessary for FF (FF fires contextmenu && click on right click)
      return;
    }

    this.selectionHandler?.handleItemClicked(event, item);
    this.stopClickEventPropagation && event.stopPropagation();
  }

  /**
   * Handle tap events. If the current selection behavior is NOT multi toggle, we want to emit a double click instead of a normal click.
   * Use case: usually lists handle double click to open something and simple click to select. In case of NOT toggle selection (which would select on simple
   * clicks), we still want to handle simple clicks as double clicks if we are on touch devices which are emitting tap events.
   */
  public onTap(event: Event, item: E): void {
    if (!this.selectionHandler) {
      return;
    }

    // event will bubble up to "empty-space" and should be ignored there so we mark the event
    ClickHandlerService.markEventAsHandled(event);

    const singleSelections = this.selectionHandler.getSelectionBehavior() !== SelectionBehavior.MULTI_TOGGLE;

    this.selectionHandler.handleItemClicked(new MouseEvent('click'), item);

    singleSelections && this.doubleClickEmitter.emit(item);
  }

  public onEmptySpaceClick(event?: Event): void {
    // do not execute logic for empty space if the click originated on an item
    const alreadyHandled = ClickHandlerService.wasEventAlreadyHandled(event);
    // do not execute logic if the perfect scrollbar was clicked
    const scrollbarClicked =
      (event?.target as HTMLElement)?.classList.contains('ps__rail-y') ||
      (event?.target as HTMLElement)?.classList.contains('ps__rail-x') ||
      (event?.target as HTMLElement)?.classList.contains('ps__thumb-x') ||
      (event?.target as HTMLElement)?.classList.contains('ps__thumb-y');

    if (!scrollbarClicked && !alreadyHandled && this.selectionHandler && this.clearSelectionOnEmptySpaceClick) {
      this.selectionHandler.clearSelection();
    }
  }

  private static wasEventAlreadyHandled(event: Event): boolean {
    return (event as any)?.clickAlreadyHandled ?? false;
  }

  private static markEventAsHandled(event: Event): void {
    (event as any).clickAlreadyHandled = true;
  }
}
