import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { switchMap } from 'rxjs';

import { tapAllResponses } from '@celum/core';
import { LibrariesProperties } from '@celum/libraries/domain';
import { ErrorService } from '@celum/shared/util';

export enum StorageLimitStatus {
  TOTAL_LIMIT_REACHED = 'TOTAL_LIMIT_REACHED',
  THRESHOLD_LIMIT_REACHED = 'THRESHOLD_LIMIT_REACHED',
  OK = 'OK'
}

export interface StorageDetailsDto {
  storageLimitStatus: StorageLimitStatus;
  usedStorageInGiB: number;
  availableStorageInGiB: number;
}

export interface LibraryStorageServiceState {
  storageLimitStatus: StorageLimitStatus;
}

@Injectable({ providedIn: 'root' })
export class LibraryStorageService extends ComponentStore<LibraryStorageServiceState> {
  public vm$ = this.select(state => state);

  public getStorageLimitStatus = this.effect(loadStorageLimit$ => {
    return loadStorageLimit$.pipe(
      switchMap(() => this.http.get<StorageDetailsDto>(`${LibrariesProperties.properties.apiUrl}/content/storage-details`)),
      tapAllResponses(
        (response: StorageDetailsDto) => this.patchState({ storageLimitStatus: response.storageLimitStatus }),
        error => this.errorService.error('LibraryStorageService', 'LIBRARIES.ERRORS.GET_STORAGE_LIMIT_DETAILS', error, false, true)
      )
    );
  });

  constructor(
    private http: HttpClient,
    private errorService: ErrorService
  ) {
    super({ storageLimitStatus: StorageLimitStatus.OK });
    this.getStorageLimitStatus();
  }
}
