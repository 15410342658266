import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output } from '@angular/core';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';

import { CelumButtonModule, CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { LibraryVariant } from '@celum/libraries/domain';
import { TranslationModule } from '@celum/ng2base';

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, MatTooltipModule, TranslationModule, CelumIconModule, CelumButtonModule],
  selector: 'libs-variants-list-row',
  templateUrl: './variants-list-row.component.html',
  styleUrls: ['./variants-list-row.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class VariantsListRowComponent {
  @Input() public variant: LibraryVariant;

  @Output() public readonly remove = new EventEmitter<LibraryVariant>();

  protected readonly icons = {
    variant: IconConfiguration.medium('variants').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(20),
    remove: IconConfiguration.large('cancel-m').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(20)
  };
}
