<div *ngIf="metadata?.length > 0" class="metadata-list_header">{{ 'COMMON.NAME' | translate }}</div>

<celum-simple-list *ngIf="metadata?.length > 0; else noMetadata" class="metadata-list" [items]="metadata" [itemTemplate]="metadataTemplate"></celum-simple-list>

<ng-template let-item #metadataTemplate>
  <libs-metadata-list-row [attr.data-test-library-metadata-list-row]="item.id" [metadata]="item" (remove)="removeItem.emit(item)"></libs-metadata-list-row>
</ng-template>

<ng-template #noMetadata>
  <empty-page class="metadata-list_empty" [config]="emptyPageConfig"></empty-page>
</ng-template>
