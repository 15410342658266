import { Action } from '@ngrx/store';

import { PageContext } from '@celum/common-components';
import { OperationStoreDefinition } from '@celum/core';

export enum MagicButtonActionTypes {
  UpdateContextAction = '[MagicButtonAction] Update Magic Button context',
  UpdateOperationsAction = '[MagicButtonAction] Update operations',
  EvaluateOperationsAction = '[MagicButtonAction] Evaluate operations'
}

export class UpdateContextAction implements Action {

  public readonly type = MagicButtonActionTypes.UpdateContextAction;

  constructor(public context: PageContext) {
  }
}

export class EvaluateOperationsAction implements Action {

  public readonly type = MagicButtonActionTypes.EvaluateOperationsAction;

}

export class UpdateOperationsAction implements Action {

  public readonly type = MagicButtonActionTypes.UpdateOperationsAction;

  constructor(public featured: OperationStoreDefinition[], public operations: OperationStoreDefinition[]) {
  }
}

export type MagicButtonActions = UpdateContextAction | UpdateOperationsAction;
