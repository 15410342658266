import { HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { catchError, EMPTY, map, Observable, switchMap, take, tap } from 'rxjs';

import { tapAllResponses } from '@celum/core';
import { LibraryStatus } from '@celum/libraries/domain';
import { Library, LibraryFindOptions, LibraryService, UpdateRequestType } from '@celum/libraries/shared';
import { selectEntitiesById } from '@celum/ng2base';
import { ErrorService, PagedListState, Result, SimplePagedListService } from '@celum/shared/util';

import { LibraryCountService } from '../../library-count.service';

export interface LibraryListViewModel {
  libraries: Observable<Library[]>;
  showEmptyPage: boolean;
  hasBottom: boolean;
  loading: boolean;
}

export interface LibraryListState {
  filter: Partial<LibraryFindOptions>;
}

const LIBRARY_LIST_SERVICE = 'LibraryListService';

@Injectable()
export class LibraryListService extends SimplePagedListService<string, LibraryListState> {
  public vm$: Observable<LibraryListViewModel> = this.select(state => this.createViewModel(state));

  public deleteLibrary = this.effect<string>(libraryId$ =>
    libraryId$.pipe(
      switchMap(id => this.libraryService.delete(id).pipe(map(() => id))),
      tapAllResponses(
        () => {
          this.libraryCountService.loadTotalNumberOfLibraries();
          this.libraryCountService.loadTotalNumberOfOwnLibraries();
          if (this.get().data.length === 1) {
            this.load();
          }
        },
        (error: HttpErrorResponse) => {
          if ([401, 403].includes(error?.status)) {
            this.errorService.error(LIBRARY_LIST_SERVICE, 'LIBRARIES.ERROR.PERMISSION_DELETE', error);
          } else {
            this.errorService.httpError(error, LIBRARY_LIST_SERVICE);
          }
        }
      )
    )
  );

  public changeLibraryStatus = this.effect<{ id: string; status: LibraryStatus }>(libraryStatus$ =>
    libraryStatus$.pipe(
      switchMap(({ id, status }) =>
        this.libraryService
          .update(id, {
            status,
            updateRequestType: UpdateRequestType.status
          })
          .pipe(
            take(1),
            map(() => id)
          )
      ),
      tapAllResponses(
        () => {
          this.load();
        },
        (error: HttpErrorResponse) => {
          if ([401, 403].includes(error?.status)) {
            this.errorService.error(LIBRARY_LIST_SERVICE, `LIBRARIES.ERROR.PERMISSION_${status}`, error.error);
          } else if (error?.status === 409) {
            this.errorService.error(LIBRARY_LIST_SERVICE, `LIBRARIES.ERROR.LIBRARY_STATUS_CONFLICT`, error.error);
          } else {
            this.errorService.httpError(error, LIBRARY_LIST_SERVICE);
          }
          return EMPTY;
        }
      )
    )
  );

  constructor(
    private libraryService: LibraryService,
    private store: Store,
    private libraryCountService: LibraryCountService,
    private errorService: ErrorService
  ) {
    super(
      {
        serverCall: (offset: number, batchSize: number) => this.loadLibraries(offset, batchSize),
        batchSize: 15
      },
      {
        filter: {}
      }
    );
  }

  public loadWithFilter(filter?: Partial<LibraryFindOptions>): void {
    this.patchState({ filter });
    super.load();
  }

  private loadLibraries(offset: number, batchSize: number): Observable<Result<string>> {
    const options: LibraryFindOptions = {
      size: batchSize,
      page: batchSize === 0 ? 0 : offset / batchSize,
      sort: ['status,asc', 'modifiedAt,desc'],
      ...this.get().filter
    };

    return this.libraryService.find(options).pipe(
      map((data: any) => ({
        paginationInfo: {
          hasBottom: data.paging.hasBottom,
          hasTop: data.paging.hasTop,
          totalElementCount: data.paging.totalElementCount
        },
        data: data.entities.map((library: Library) => library.id)
      })),
      tap((result: Result<string>) => {
        this.libraryCountService.setTotalNumberOfLibraries(result.paginationInfo.totalElementCount);
      }),
      take(1),
      catchError((error: HttpErrorResponse) => {
        this.errorService.httpError(error, LIBRARY_LIST_SERVICE);
        return [];
      })
    );
  }

  private createViewModel(state: PagedListState<string>): LibraryListViewModel {
    const libraries$ = this.store.select(selectEntitiesById<Library>(state.data));

    return {
      libraries: libraries$,
      loading: state.loading,
      hasBottom: state.paginationInfo?.hasBottom,
      showEmptyPage: !state.loading && state.data.length === 0
    };
  }
}
