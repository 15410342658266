import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';

import { CelumPipesModule, TranslationModule } from '@celum/ng2base';

import { MessageBox } from './components/message-box';
import { Message } from './message';
import { CelumIconModule } from '../icon';

@NgModule({
            imports: [
              CommonModule, TranslateModule,

              CelumPipesModule, TranslationModule,

              CelumIconModule
            ],
            declarations: [
              Message, MessageBox
            ],
            exports: [
              Message, MessageBox
            ]
          })
export class CelumMessageModule {
}
