import { Directive } from '@angular/core';
import { AbstractControl, NG_VALIDATORS, ValidatorFn } from '@angular/forms';

// eslint-disable-next-line @typescript-eslint/no-shadow
export function maxLength(maxLength: number): ValidatorFn {
  // do not return function inline -> ngc doesn't like that
  const fn = (control: AbstractControl): { [key: string]: any } => {
    // consider spaces in characters!
    const length: number = control.value ? control.value.length : 0;
    return length > maxLength ? {
      maxLength: {
        valid: false,
        messageKey: 'VALIDATION.MAX_LENGTH',
        params: {
          maxLength,
          actualLength: length
        }
      }
    } : null;
  };

  return fn;
}

@Directive({
             selector: '[maxLength][ngModel]',
             providers: [
               {
                 provide: NG_VALIDATORS,
                 useValue: maxLength,
                 multi: true
               }
             ]
           })
export class MaxLengthValidator {

}
