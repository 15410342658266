import { LicenseType } from '@celum/authentication';
import { IconConfiguration } from '@celum/common-components';
import { CelumPropertiesProvider } from '@celum/core';

import { ServiceTabItemConfiguration } from '../../model/service-tab-item/service-tab-item-configuration';

export const CONTENT_HUB_SERVICE_NAME = 'Content';
const CONTENT_HUB_SERVICE_ICON = 'content-hub-main';

export const WORKROOMS_SERVICE_NAME = 'Work';
const WORKROOMS_SERVICE_ICON = 'workroom';

export const LIBRARIES_SERVICE_NAME = 'Libraries';
const LIBRARIES_SERVICE_ICON = 'libraries';

export const EXPERIENCE_SERVICE_NAME = 'Experience';
const EXPERIENCE_SERVICE_ICON = 'experience';

export const CONTENT_TAB_ITEM: Omit<ServiceTabItemConfiguration, 'active' | 'onClick'> = {
  text: CONTENT_HUB_SERVICE_NAME,
  iconConfiguration: new IconConfiguration(CONTENT_HUB_SERVICE_ICON).withColor('#fff'),
  subServices: null,
  dataComponentId: 'dashboard',
  licenseType: LicenseType.CONTENT_HUB,
  fallbackUrlPerLanguage: CelumPropertiesProvider.properties.systemBar.contentHubFallbackUrlPerLanguage
};

export const WORKROOMS_TAB_ITEM: Omit<ServiceTabItemConfiguration, 'active' | 'onClick'> = {
  text: WORKROOMS_SERVICE_NAME,
  iconConfiguration: new IconConfiguration(WORKROOMS_SERVICE_ICON).withColor('#fff'),
  subServices: null,
  dataComponentId: 'workflow',
  licenseType: LicenseType.WORK,
  applicationUrl: CelumPropertiesProvider.properties.systemBar.workAppUrl
};

export const LIBRARIES_TAB_ITEM: Omit<ServiceTabItemConfiguration, 'active' | 'onClick'> = {
  text: LIBRARIES_SERVICE_NAME,
  iconConfiguration: new IconConfiguration(LIBRARIES_SERVICE_ICON).withColor('#fff'),
  subServices: null,
  dataComponentId: 'libraries',
  licenseType: LicenseType.LIBRARIES,
  applicationUrl: CelumPropertiesProvider.properties.systemBar.librariesAppUrl,
  fallbackUrlPerLanguage: CelumPropertiesProvider.properties.systemBar.librariesFallbackUrlPerLanguage
};

export const EXPERIENCE_TAB_ITEM: Omit<ServiceTabItemConfiguration, 'active' | 'onClick'> = {
  text: EXPERIENCE_SERVICE_NAME,
  iconConfiguration: new IconConfiguration(EXPERIENCE_SERVICE_ICON).withColor('#fff'),
  subServices: null,
  dataComponentId: 'portals',
  licenseType: LicenseType.EXPERIENCE,
  applicationUrl: CelumPropertiesProvider.properties.systemBar.experienceAppUrl,
  fallbackUrlPerLanguage: CelumPropertiesProvider.properties.systemBar.experienceFallbackUrlPerLanguage
};
