import { Observable, Subject } from 'rxjs';

import { IconConfiguration } from '@celum/common-components';

import { SubServiceTabItemConfiguration } from './sub-service-tab-item-configuration';

export class SubServiceTabItem {

  public text: string;
  public iconConfiguration: IconConfiguration;
  public onClick: () => void;
  public dataComponentId: string;
  private pSelected: boolean;
  private selectedValueChanged = new Subject<boolean>();

  constructor(obj: SubServiceTabItemConfiguration) {
    this.text = obj.text;
    this.iconConfiguration = obj.iconConfiguration;
    this.onClick = obj.onClick;
    this.dataComponentId = obj.dataComponentId;
    this.pSelected = obj.selected ?? false;
  }

  public set selected(selected: boolean) {
    this.pSelected = selected;
    this.selectedValueChanged.next(selected);
  }

  public get selected(): boolean {
    return this.pSelected;
  }

  public get onSelectedValueChanged(): Observable<boolean> {
    return this.selectedValueChanged.asObservable();
  }

  public deselect(): void {
    this.selected = false;
  }
}
