<celum-icon class="metadata-list-row_icon" [clickable]="false" [configuration]="icons.metadata"></celum-icon>
<div class="metadata-list-row_name">{{ metadata.labels | language }}</div>

<celum-icon
  *ngIf="metadata.error"
  class="metadata-list-row_warning"
  data-test-library-metadata-warning-icon
  [clickable]="false"
  [configuration]="icons.warning"
  [matTooltip]="'LIBRARIES.CREATE.METADATA.MISSING_METADATA_WARNING' | translate"></celum-icon>

<button celum-square-button data-test-library-metadata-list-row-remove-button mat-button (click)="remove.emit(metadata)">
  <celum-icon [configuration]="icons.remove" [matTooltip]="'COMMON.REMOVE' | translate"></celum-icon>
</button>
