import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { catchError, EMPTY, EmptyError, finalize, map, Observable, SequenceError, single, Subject, switchMap, takeUntil, tap, throwError } from 'rxjs';
import { filter, take } from 'rxjs/operators';

import { AnyObject, IPaginationResult } from '@celum/core';

export type Result<RESULT> = {
  paginationInfo: IPaginationResult;
  data: RESULT[];
};

export type PagedListState<ENTITY> = {
  offset: number;
  paginationInfo?: IPaginationResult;
  loadingBatch: boolean;
  loading: boolean;
  data: ENTITY[];
};

export type PagedListOptions<RESULT, ENTITY> = {
  serverCall: (batchSize: number, offset: number) => Observable<Result<RESULT>>;
  batchSize?: number;
  resultMapper?: (resultData: RESULT[]) => ENTITY[];
  /* Define when data will be reset. Default is `beforeRequest` */
  cleanupData?: 'beforeRequest' | 'afterRequest';
};

/**
 * Utility service to handle simple batched request.
 */
@Injectable()
export abstract class SimplePagedListService<ENTITY, STATE = AnyObject, RESULT = ENTITY> extends ComponentStore<STATE & PagedListState<ENTITY>> {
  protected serverCall: (offset: number, batchSize: number) => Observable<Result<RESULT>>;
  protected batchSize: number;
  protected resultMapper: (resultData: RESULT[]) => ENTITY[];
  protected cancel$ = new Subject<void>();

  private readonly cleanupData: 'beforeRequest' | 'afterRequest';

  constructor(options: PagedListOptions<RESULT, ENTITY>, initialState?: STATE) {
    super({ offset: 0, loading: false, loadingBatch: false, data: [], ...initialState } as STATE & PagedListState<ENTITY>);

    this.serverCall = options.serverCall;
    this.batchSize = options.batchSize ?? 20;
    this.resultMapper = options.resultMapper ?? this.defaultMapper;
    this.cleanupData = options.cleanupData ?? 'beforeRequest';

    this.destroy$.subscribe(() => {
      this.cancel$.next();
      this.cancel$.complete();
    });
  }

  public updateBatchSize(batchSize: number): void {
    this.batchSize = batchSize;
  }

  /**
   * Load the next batch, based on the provided `batchSize`.
   * You may subscribe to the returned observable to get notified when the result was returned and processed.
   * In case there is no next batch available, or one is already being loaded, the returned observable will complete immediately without emitting.
   */
  public loadNextBatch(): void {
    if (this.get().loadingBatch || this.get().loading) {
      console.debug(`PagedListService: Ignore call for loading next batch, as a batch is currently loading.`);
      return;
    }

    if (!this.get().paginationInfo?.hasBottom) {
      console.debug(`PagedListService: Ignore call for loading next batch, there is nothing more to load.`);
      return;
    }

    this.patch({ loadingBatch: true });
    this.executeCall('loadingBatch').subscribe();
  }

  public cancelLoading(): void {
    this.patch({ loading: false, loadingBatch: false });
    this.cancel$.next();
  }

  /**
   * Load the first batch and discard everything that was already loaded.
   * If you need to be notified about when the loading is finished, use {@link load$} instead.
   */
  public load(): void {
    this.load$().subscribe();
  }

  /**
   * Load the first batch and discard everything that was already loaded.
   * You need to subscribe to the returned observable to get notified when the result was returned and processed and especially to trigger the actual server
   * call!
   *
   * ⚠️Just calling this method will already set `loading` to true and resets the `offset` to `0`!
   */
  public load$(): Observable<void> {
    this.get().loading && this.cancelLoading(); // cancel previous load, we are only interested in the latest result

    const patch: Partial<PagedListState<ENTITY>> = { offset: 0, loading: true };

    if (this.cleanupData === 'beforeRequest') {
      patch.data = [];
    }

    this.patch(patch);

    // It sometimes happens that when executing the loading directly after the patch, the values for e.g. offset may not yet be updated. So we have to make sure
    // to wait for the correct value, else we might load the wrong batch of data.
    return this.state$.pipe(
      filter(state => state.offset === 0),
      take(1),
      switchMap(() => this.executeCall('loading', this.cleanupData === 'afterRequest'))
    );
  }

  protected patch(partial: Partial<PagedListState<ENTITY>>): void {
    this.patchState(partial as Partial<STATE & PagedListState<ENTITY>>);
  }

  protected executeCall(loadingFlag: keyof PagedListState<ENTITY>, resetData = false): Observable<void> {
    return this.serverCall(this.get().offset, this.batchSize).pipe(
      single(),
      tap(result => {
        const { offset, data } = this.get();

        this.patch({
          offset: offset + this.batchSize,
          data: [...(resetData ? [] : data), ...this.resultMapper(result.data)],
          paginationInfo: result.paginationInfo
        });
      }),
      catchError(error => {
        if (error instanceof EmptyError) {
          // empty result is okay, so just forward it (we want to enable to return EMPTY in case of a communication error)
          return EMPTY;
        } else if (error instanceof SequenceError) {
          console.error(`PagedListService: Error fetching data. Make sure that your server call only emits once!`, error);
        }

        console.error(`PagedListService: Error fetching data. You should handle errors yourself with the 'serverCall' you provide!`, error);

        return throwError(error);
      }),
      finalize(() => this.patch({ [loadingFlag]: false })),
      takeUntil(this.cancel$), // enable to cancel the call from the outside
      map(() => void 0)
    );
  }

  private defaultMapper(res: RESULT[]): ENTITY[] {
    return res as unknown as ENTITY[];
  }
}
