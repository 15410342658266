<celum-fullscreen-dialog-header [light]="light"
                                [showCloseButton]="showCloseButton"
                                [title]="headerText | translate"
                                (onClose)="close()">
  <ng-content select="[celum-wizard-header-actions]"></ng-content>
</celum-fullscreen-dialog-header>

<celum-stepper-navigation class="celum-wizard_stepper"
                          [selectedIndex]="selectedIndex"
                          [selectedStep]="selected"
                          [stepsConfiguration]="stepsConfiguration"
                          [steps]="steps.toArray()"
                          (indexChanged)="indexChanged($event)">
  <div [ngTemplateOutlet]="selected?.content"></div>
</celum-stepper-navigation>
