<mat-form-field celum-form-field
                class="celum-select-box_form-field"
                [class.celum-select-box_form-field--disabled]="disabled"
                [size]="size">

  <mat-label *ngIf="loading" class="celum-select-box_loading-label">
    <div class="celum-select-box_loading-label-spinner"></div>
    <span>{{'COMMON.LOADING' | translate}}</span>
  </mat-label>
  <mat-select #selectBox
              disableOptionCentering
              hideSingleSelectionIndicator
              [formControl]="control"
              [multiple]="multiple"
              [panelClass]="['celum-select-box_dropdown-panel', panelClass]"
              [placeholder]="placeholder">

    <message-box *ngIf="infoNote"
                 class="celum-select-box_message-box"
                 [text]="infoNote"
                 [type]="infoNoteStatus"></message-box>

    <mat-select-trigger *ngIf="stickyPlaceHolder && multiple && !customLabel" class="celum-select-box_placeholder"
                        data-test-open-select-box>{{placeholder}}</mat-select-trigger>

    <mat-option *ngFor="let option of options; trackBy: trackByFn"
                class="celum-select-box_option"
                spaceAwareTooltip
                [attr.data-test-celum-select-box-option]="option | wrapFn: getOptionValue"
                [disabled]="option | wrapFn : isDisabled:  control.value : atLeastOne"
                [matTooltip]="option | wrapFn : getTooltip : control.value : atLeastOneTooltip : atLeastOne"
                [value]="option | wrapFn: getOptionValue ">
      <span class="celum-select-box_option-value" spaceAwareTooltip [matTooltip]="option | wrapFn: getOptionLabel | translate | truncate">{{ option | wrapFn: getOptionLabel | translate | truncate }}</span>
      <celum-icon *ngIf="option | wrapFn: getInfoTooltip as tooltip" class="celum-select-box_option-info-icon" [configuration]="infoIcon"
                  [matTooltip]="tooltip | translate"></celum-icon>
    </mat-option>
    <mat-select-trigger *ngIf="!multiple || customLabel">
      <ng-container *ngIf="(customLabel || (control.value | wrapFn: getOption:options)) as option">
        <span class="celum-select-box_option-value" spaceAwareTooltip [matTooltip]="option | wrapFn: getOptionLabel | translate : customLabelOptions | truncate">
          {{ option | wrapFn: getOptionLabel | translate : customLabelOptions | truncate }}
        </span>
        <celum-icon *ngIf="option | wrapFn: getInfoTooltip as tooltip" class="celum-select-box_option-info-icon" [configuration]="infoIcon"
                    [matTooltip]="tooltip | translate"></celum-icon>
      </ng-container>
    </mat-select-trigger>
  </mat-select>
  <celum-icon matSuffix [configuration]="arrowDownIcon"></celum-icon>
  <mat-error *ngIf="control.hasError('required')">
    {{ 'VALIDATION.REQUIRED' | translate }}</mat-error>
</mat-form-field>
