import { EMPTY, Observable } from 'rxjs';
import { fromPromise } from 'rxjs/internal/observable/innerFrom';

export class ImportUtil {
  private static loadingModules = new Set<string>();
  private static moduleCallbacks = new Map<string, (module: any) => any>();

  /**
   * Loads a module dynamically
   * Importing a module using this function will ensure that no action is executed until the first import is finished.
   * @param id The id of the module
   * @param load The import() function to load the module
   */
  public static dynamicImport<C>(id: string, load: () => Promise<C>): Observable<C> {
    if (this.loadingModules.has(id)) {
      return EMPTY;
    }

    this.loadingModules.add(id);

    return fromPromise(
      load().then((module: C) => {
        this.loadingModules.delete(id);
        return module;
      })
    );
  }

  /**
   * Load a module dynamically and execute a callback function
   * Calling this function multiple times with the same id will make sure that the
   * latest callback function is executed after the module has been loaded
   * @param id The id of the module
   * @param load The import() function to load the module
   * @param callback The callback function to execute after the module has been loaded
   */
  public static dynamicImportSwitch<C>(id: string, load: () => Promise<C>, callback: (module: C) => void): void {
    this.moduleCallbacks.set(id, callback);

    this.dynamicImport(id, load).subscribe(module => {
      this.moduleCallbacks.get(id)(module);
      this.moduleCallbacks.delete(id);
    });
  }
}
