<ng-container *ngIf="vm$ | async as vm">
  <celum-icon
    class="libs-variants-filter_add-variant"
    cdkOverlayOrigin
    data-test-variants-new-variant-button
    [configuration]="addNewVariant"
    [matTooltip]="'LIBRARIES.CREATE.VARIANTS.ADD_VARIANTS_TOOLTIP' | translate"
    #originOverlay="cdkOverlayOrigin"
    (click)="menuOpened(true)"></celum-icon>

  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="vm.isOpen"
    [cdkConnectedOverlayOrigin]="originOverlay"
    [cdkConnectedOverlayPanelClass]="'mat-elevation-z3'"
    (overlayOutsideClick)="menuOpened(false)">
    <celum-search-and-select
      class="libs-variants-filter_search-and-select"
      data-test-library-variants-selector
      [itemTemplate]="itemTemplate"
      [items]="vm.filteredValues"
      [loadingItems]="vm.loading"
      [multipleSelection]="true"
      [noItemFoundIconSize]="122"
      [noItemFoundIcon]="'no-variants'"
      [noItemText]="'LIBRARIES.CREATE.VARIANTS.NO_VARIANTS_FOUND'"
      [searchBoxPlaceholderText]="'LIBRARIES.CREATE.VARIANTS.VARIANTS_SEARCH'"
      [searchValue]="vm.searchValue"
      [selectedItems]="vm.selectedValues"
      (itemSelectionChanged)="itemSelectionChanged($event)"
      (searchValueChanged)="patchState({ searchValue: $event })"></celum-search-and-select>
  </ng-template>

  <ng-template #itemTemplate let-item>
    <span class="libs-variants-filter_item checkbox">{{ item.name | language }}</span>
  </ng-template>
</ng-container>
