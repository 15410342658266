import { AvatarConfiguration } from '@celum/common-components';
import { LocalizedValue } from '@celum/core';

import { LibrarySyncDirection, LibrarySyncTrigger } from './library-sync-definition';

export enum LibraryStatus {
  ACTIVE = 'ACTIVE',
  INACTIVE = 'INACTIVE'
}

export interface AssetMetadataFieldDto {
  externalId: string;
  name: string;
  type: string;
  labels: LocalizedValue;
}

export interface AssetFilterDto {
  externalId: string;
  name: string;
}

export interface LibrarySyncSourceDto {
  assetRepositoryId: string;
  downSyncFilter: AssetFilterDto;
  infoFieldMapping: AssetMetadataFieldDto[];
}

export interface LibrarySyncDefinitionDto {
  syncDirection: LibrarySyncDirection;
  syncTrigger: LibrarySyncTrigger;
  syncSource: LibrarySyncSourceDto;
}

export enum LibraryErrorKey {
  'USER_NOT_CONNECTED' = 'library.sync.contenthub.user-not-connected',
  'STORED_SEARCH_NOT_FOUND' = 'library.sync.contenthub.stored-search-not-found',
  'USER_INACTIVE' = 'user.inactive'
}

export interface LibraryError {
  key: LibraryErrorKey | string;
  errorCount: number;
  lastOccurrence: Date;
}

export interface LibraryDto {
  id: string;
  name: string;
  avatars: AvatarConfiguration[];
  status: LibraryStatus;
  syncDefinition: LibrarySyncDefinitionDto;
  hidden: boolean;
  readonly: boolean;
  createdAt: string;
  modifiedAt: string;
  createdBy: string;
  variantTypes: string[];
  error: LibraryError;
}
