import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map, Observable, of } from 'rxjs';

import { ExperienceProperties } from '@celum/experience/domain';
import { EntityResolver, ResolverCallConfig, Result, ResultConsumerService, schemaOf } from '@celum/ng2base';
import { ResourceService } from '@celum/shared/domain';
import { ErrorService } from '@celum/shared/util';

import { USAGE_STATISTICS, UsageStatistics } from '../usage-statistics.entity';

@Injectable({ providedIn: 'root' })
export class LibraryUsageStatisticsService implements ResourceService<UsageStatistics>, EntityResolver {
  private readonly libraryUsageStatisticsMetaInfo = {
    schema: [schemaOf(USAGE_STATISTICS)],
    resultKey: `content`
  };

  constructor(
    private http: HttpClient,
    private consumer: ResultConsumerService,
    private errorService: ErrorService
  ) {}

  public getUsageStatisticsForLibrary(libraryIds: string[]): Observable<{ content: UsageStatistics[] }> {
    let params = new HttpParams();
    libraryIds.forEach(libraryId => {
      params = params.append('libraryIds', libraryId);
    });

    return this.http.get<{ usageStatistics: { [key: string]: UsageStatistics } }>(`${ExperienceProperties.properties.apiUrl}/statistics`, { params }).pipe(
      map(response => {
        return {
          content: this.convertResult(response)
        };
      }),
      catchError(error => {
        this.errorService.error('LibraryUsageStatisticsService', 'LIBRARIES.ERROR.USAGE_STATISTICS', error, false, true);
        return of({ content: [] });
      })
    );
  }

  public getMany(ids: string[]): ResolverCallConfig {
    const truncatedIds = ids.map(id => id.split('_')[0]);

    const response$ = this.getUsageStatisticsForLibrary(truncatedIds);

    return {
      response$,
      metaInfo: this.libraryUsageStatisticsMetaInfo
    };
  }

  public create(creator: unknown, options?: unknown): Observable<UsageStatistics> {
    throw new Error('LibraryUsageStatisticsService: Method not implemented.');
  }

  public delete(id: string, options?: unknown): Observable<void> {
    throw new Error('LibraryUsageStatisticsService: Method not implemented.');
  }

  public find(options?: string[]): Observable<Result<UsageStatistics>> {
    const libraryId = options[0];

    if (!libraryId) {
      return of(null);
    }

    const response$ = this.getUsageStatisticsForLibrary([libraryId]).pipe(
      map(response => {
        return {
          content: response
        };
      })
    );

    return this.consumer.consume<UsageStatistics>(response$, this.libraryUsageStatisticsMetaInfo);
  }

  public findOne(options: unknown): Observable<UsageStatistics> {
    throw new Error('LibraryUsageStatisticsService: Method not implemented.');
  }

  public update(id: string, updater?: unknown): Observable<UsageStatistics> {
    throw new Error('LibraryUsageStatisticsService: Method not implemented.');
  }

  private convertResult(response: { usageStatistics: { [p: string]: UsageStatistics } }): UsageStatistics[] {
    return Object.entries(response.usageStatistics).map(([libraryId, usageStatistics]) => ({
      id: `${libraryId}_usage_statistics`,
      typeKey: USAGE_STATISTICS,
      ...usageStatistics
    }));
  }

  public static getLanguageKeyEnding(usageStatistics: UsageStatistics): string {
    const count = usageStatistics?.active + usageStatistics?.published;

    if (!count) {
      return '';
    }

    const activeKey = count === 1 ? '1' : 'N';
    const publishedKey = usageStatistics.published === 1 ? '1' : count === 1 ? '0' : 'N';

    return `_${activeKey}${publishedKey}`;
  }
}
