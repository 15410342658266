<celum-dialog [hideCloseAllBtn]="true">
  <celum-dialog-header class="confirmation-with-list-dialog_header">
    <span [innerHTML]="config.headerText | translate: config.headerParams"></span>
  </celum-dialog-header>

  <div class="confirmation-with-list-dialog_content">
    <div class="confirmation-with-list-dialog_hint" *ngFor="let item of config.items">
      <celum-icon class="confirmation-with-list-dialog_hint-icon" [configuration]="item.icon" [clickable]="false"></celum-icon>
      <span [innerHTML]="item.text | translate: item.translationParams | sanitized"></span>
    </div>
  </div>

  <celum-dialog-footer
    [confirmBtnText]="config.confirmButtonText"
    (onCancel)="dialogRef.close(false)"
    (onConfirm)="dialogRef.close(true)"></celum-dialog-footer>
</celum-dialog>
