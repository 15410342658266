import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnDestroy, OnInit, Output, ViewEncapsulation
} from '@angular/core';
import { Subscription } from 'rxjs';

import { SubServiceTabItem } from '../../model/sub-service-tab-item/sub-service-tab-item';

@Component({
             selector: 'systembar-sub-service-tab-item',
             templateUrl: './sub-service-tab-item-component.html',
             styleUrls: ['./sub-service-tab-item-component.less'],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class SubServiceTabItemComponent implements OnInit, OnDestroy {
  @Input() public subService: SubServiceTabItem;

  @Output() public readonly onClick = new EventEmitter();

  @HostBinding('class.systembar-sub-service-tab-item') public hostCls = true;
  private subscription: Subscription;

  constructor(private changeDetector: ChangeDetectorRef) {
  }

  public ngOnInit(): void {
    this.subscription = this.subService.onSelectedValueChanged.subscribe((value: boolean) => this.changeDetector.markForCheck());
  }

  public ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }

  public clicked(): void {
    if (this.subService.onClick) {
      this.subService.onClick();
    }

    this.onClick.emit(this.subService);
  }
}
