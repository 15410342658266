import { createFeatureSelector, createSelector } from '@ngrx/store';

export interface SnackbarCreationInformation {
  id: string;
  config: any;
  placeOnTop: boolean;
}

export interface SnackbarReducerState {
  [key: string]: SnackbarCreationInformation;
}

export const initialSnackbarState: SnackbarReducerState = {};

const selectSnackbar = createFeatureSelector<SnackbarReducerState>('snackbar');

export const getSnackbars = createSelector(selectSnackbar, state => {
  const ids = Object.keys(state) || [];
  return ids.map(id => state[id]);
});
