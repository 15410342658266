<ng-container *ngIf="service.vm$ | async as vm">
  <div class="libs-assets-filter_row">
    <div cdkOverlayOrigin #originOverlay="cdkOverlayOrigin">
      <celum-chip
        *ngIf="vm.selectedValues.length > 0"
        data-test-assets-filter-selected-filter
        [attr.data-test-assets-filter-selected-filter]="vm.selectedValues[0].id"
        [color]="'light'"
        [errorTooltip]="vm.error && ('LIBRARIES.ERROR.' + vm.error.key.toUpperCase() | translate)"
        [prefixIcon]="'filter-m'"
        [text]="vm.selectedValues[0].name | language"
        (click)="service.menuOpened(true)"
        (remove)="service.itemSelectionChanged([])"></celum-chip>
      <celum-icon
        *ngIf="vm.selectedValues?.length === 0"
        data-test-asset-filter-new-filter-button
        [configuration]="vm.icons.addNewFilter"
        [matTooltip]="'LIBRARIES.CREATE.ASSETS.ADD_FILTER_TOOLTIP' | translate"
        (click)="service.menuOpened(true)"></celum-icon>
    </div>

    <ng-template
      cdkConnectedOverlay
      [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
      [cdkConnectedOverlayHasBackdrop]="true"
      [cdkConnectedOverlayOpen]="vm.isOpen"
      [cdkConnectedOverlayOrigin]="originOverlay"
      [cdkConnectedOverlayPanelClass]="'mat-elevation-z3'"
      (overlayOutsideClick)="service.menuOpened(false)">
      <celum-search-and-select
        class="libs-assets-filter_search-and-select"
        data-test-library-assets-filter
        [itemTemplate]="itemTemplate"
        [items]="vm.filteredValues"
        [loadingItems]="vm.loading"
        [multipleSelection]="false"
        [noItemFoundIconSize]="70"
        [noItemFoundIcon]="'no-filter'"
        [noItemText]="vm.searchValue !== null ? 'LIBRARIES.CREATE.ASSETS.NO_FILTER_FOUND' : 'LIBRARIES.CREATE.ASSETS.NO_FILTER'"
        [searchBoxPlaceholderText]="'LIBRARIES.CREATE.ASSETS.FILTER_SEARCH'"
        [searchValue]="vm.searchValue"
        [selectedItems]="vm.selectedValues"
        (itemSelectionChanged)="service.itemSelectionChanged($event)"
        (searchValueChanged)="service.patchState({ searchValue: $event })"></celum-search-and-select>

      <ng-template #itemTemplate let-item>
        <span class="libs-assets-filter_item checkbox" spaceAwareTooltip [matTooltip]="item.name | language">{{ item.name | language }}</span>
      </ng-template>
    </ng-template>
  </div>
</ng-container>
