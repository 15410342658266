import { ServiceTabItemConfiguration } from './service-tab-item-configuration';
import { SimpleContextMenuItem } from '../simple-content-menu-item';
import { SubServiceTabItem } from '../sub-service-tab-item/sub-service-tab-item';

export class ServiceTabItem extends SubServiceTabItem {

  public active: boolean;
  public interactable: boolean;
  public subServices: SubServiceTabItem[];
  public contextMenuItems: SimpleContextMenuItem[];
  public applicationUrl: string;
  public disabled: boolean;

  constructor(obj: ServiceTabItemConfiguration) {
    super(obj);

    this.active = obj.active ?? true;
    this.interactable = obj.interactable ?? true;
    this.subServices = obj.subServices || [];
    this.contextMenuItems = obj.contextMenuItems || [];
    this.applicationUrl = obj.applicationUrl || '';
    this.disabled = obj.disabled || false;
  }

  public deselect(): void {
    super.deselect();
    this.subServices?.forEach(subService => subService.deselect());
  }
}
