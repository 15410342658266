/**
 * Removes loading overlay when present
 * @param className class name of the loading overlay, defaults to 'celum-loading-overlay'
 */
export function removeCelumLoadingOverlay(className = 'celum-loading-overlay'): void {
  const overlay = document.getElementsByClassName(className)?.[0] as HTMLElement;

  if (overlay) {
    overlay.style.opacity = '0';
    setTimeout(() => document.body.removeChild(overlay), 100);
  }
}
