import { IPaginationResult } from '@celum/core';

/** Function that extracts and converts the pagination result on a raw backend response into a unified format */
export function translateLibraryPaginationResult(response: any): IPaginationResult {
  return {
    hasBottom: !response.last,
    hasTop: !response.first,
    totalElementCount: response.totalElements
  };
}
