import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Input } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { MatIconModule } from '@angular/material/icon';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { switchMap, take } from 'rxjs';

import {
  CelumButtonModule,
  CelumEmptyPageModule,
  CelumIconModule,
  ColorConstants,
  EmptyPage,
  IconConfiguration,
  MessageDialog,
  MessageDialogConfiguration
} from '@celum/common-components';
import { isTruthy } from '@celum/core';
import { CelumDialogOpener, CelumListModule } from '@celum/internal-components';
import { LibrariesProperties, LibraryStatus } from '@celum/libraries/domain';
import { LibraryWizardApi } from '@celum/libraries/features/designer';
import { Library } from '@celum/libraries/shared';
import { LibraryDialogConfigurationUtils, LibraryListRowComponent } from '@celum/libraries/ui';
import { CelumDirectivesModule, TranslationModule } from '@celum/ng2base';
import { ConfirmationWithListDialog, ConfirmationWithListDialogConfiguration } from '@celum/shared/ui';

import { LibraryListService } from './library-list.service';
import { LibraryFilter } from '../../dashboard.component';

@Component({
  standalone: true,
  selector: 'libs-library-list',
  imports: [
    CommonModule,
    MatIconModule,
    MatTooltipModule,

    CelumButtonModule,
    CelumDirectivesModule,
    CelumEmptyPageModule,
    CelumIconModule,
    CelumListModule,
    TranslationModule,

    LibraryListRowComponent
  ],
  templateUrl: './library-list.component.html',
  styleUrls: ['./library-list.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LibraryListComponent {
  @Input()
  public header: string;

  protected LibraryStatus = LibraryStatus;

  protected arrowDownIcon = IconConfiguration.xSmall('arrow-down-xs').withColor(ColorConstants.BLUE_GRAY_900);

  protected panelOpen = true;

  protected noResultsConfig = EmptyPage.noActionConfig('empty-libraries-list', 'empty-libraries-list', 'LIBRARIES.EMPTY.NO_RESULTS', 'normal', 232);

  constructor(
    protected libraryListService: LibraryListService,
    private dialogOpener: CelumDialogOpener,
    private dialog: MatDialog,
    private router: Router,
    private libraryWizardApi: LibraryWizardApi,
    private store: Store
  ) {}

  @Input()
  public set filters(value: LibraryFilter[]) {
    const mappedFilters = value.reduce((previous, current) => ({ ...previous, [current.key]: current.value }), {});
    this.libraryListService.loadWithFilter(mappedFilters);
  }

  protected openLibraryPreview(library: Library): void {
    this.router.navigate([`preview/${library.id}`]);
  }

  protected openLibraryJSON(library: Library): void {
    if (!LibrariesProperties.properties.apiUrl.includes('celum.com')) {
      import('../../components/library-preview/library-preview.component').then(component =>
        this.dialog.open(component.LibraryPreviewComponent, {
          width: '100vw',
          height: '100vh',
          maxWidth: '100vw',
          data: library
        })
      );
    }
  }

  protected changeStateOfLibrary(library: Library, status: LibraryStatus): void {
    if (status === LibraryStatus.ACTIVE) {
      this.takeActionWithConfirm(LibraryDialogConfigurationUtils.getActivateLibraryDialogConfiguration(library), () =>
        this.libraryListService.changeLibraryStatus({ id: library.id, status })
      );
      return;
    }

    library
      .usageStatistics(this.store)
      .pipe(take(1))
      .subscribe(statistics =>
        this.takeActionWithConfirm(LibraryDialogConfigurationUtils.getDeactivateLibraryDialogConfiguration(library, statistics), () =>
          this.libraryListService.changeLibraryStatus({ id: library.id, status })
        )
      );
  }

  protected deleteLibrary(library: Library): void {
    const messageDialogConfiguration = MessageDialogConfiguration.createWarnConfig('LIBRARIES.DIALOGS.DELETE.TEXT', 'LIBRARIES.DIALOGS.DELETE.HEADER')
      .withButtons(['ok', 'cancel'])
      .withOkButtonText('LIBRARIES.DIALOGS.DELETE.CONFIRM')
      .withHideCloseButton();
    this.dialogOpener.showDialog(messageDialogConfiguration.moduleId, MessageDialog, messageDialogConfiguration).then((result: boolean) => {
      if (result) {
        this.libraryListService.deleteLibrary(library.id);
      }
    });
  }

  protected editLibrary(library: Library): void {
    this.libraryWizardApi
      .open(library)
      .pipe(switchMap(dialog => dialog.afterClosed()))
      .subscribe(result => {
        if (result) {
          this.libraryListService.load();
        }
      });
  }

  protected takeActionWithConfirm(configuration: ConfirmationWithListDialogConfiguration, action: () => void): void {
    this.dialog
      .open(ConfirmationWithListDialog, { data: configuration })
      .afterClosed()
      .pipe(isTruthy())
      .subscribe(() => action());
  }
}
