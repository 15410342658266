import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';

import { AuthService, UserInfoLoadingError } from '@celum/authentication';

import { ErrorService } from './error-service';

/**
 * Application-wide error handler.
 */
@Injectable()
export class AppErrorHandler extends ErrorHandler {
  private delayUserInfoErrorSignOut = 5000;
  constructor(
    private errorService: ErrorService,
    private authService: AuthService
  ) {
    super();
  }

  public handleError(error: Error): void {
    let message = '';
    if (error instanceof UserInfoLoadingError) {
      this.errorService.error('App', 'ERROR.USER_FETCHING', error);
      setTimeout(() => this.authService.signOut().subscribe(), this.delayUserInfoErrorSignOut);
    } else {
      if (error instanceof HttpErrorResponse) {
        message = 'ERROR.COMMUNICATION';
      } else if (error instanceof Error) {
        message = error.message ? error.message : error.toString();
      }

      this.errorService.unexpected(message, error);
    }
  }
}
