<div class="magic-button-entry_button"
     *ngIf="viewModel$ | async as viewModel"
     [attr.operation-id]="operation?.getKey()"
     [matTooltip]="(viewModel.tooltip ? (operation.getMessageKey() | language | uppercase) + '&#13;' : '') + ((viewModel.disabledText | translate) ?? '')"
     [matTooltipDisabled]="!viewModel.disabled"
     [matTooltipShowDelay]="500"
     matTooltipClass="magic-button-entry_tooltip"
     (click)="onClick()">
  <div class="operation_icon operation_icon-small">
    <celum-icon [configuration]="iconConfig"></celum-icon>
  </div>
  <div class="operation_name"
       [matTooltip]="(operation.getMessageKey() | language | uppercase)"
       [matTooltipDisabled]="!viewModel.tooltip || viewModel.disabled"
       [matTooltipShowDelay]="500">
    {{ operation.getMessageKey() | language }}
  </div>
</div>
