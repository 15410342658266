import { Directive, Input } from '@angular/core';

import { ListSelectionHandler } from '../services/list-selection-handler.service';

@Directive({
             selector: '[celum-list-selectable]',
             exportAs: 'celumListSelectable',
             standalone: true
           })
export class ListSelectableDirective<E> extends ListSelectionHandler<E> {

  @Input() public updateSelectionOnItemsChange = true;

}
