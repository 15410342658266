import { Injectable } from '@angular/core';
import { map, skip, switchMap, take, takeUntil } from 'rxjs';

import { UserService } from '@celum/authentication';
import { isTruthy } from '@celum/core';
import { ReactiveComponent, TranslationHelper } from '@celum/ng2base';

@Injectable({ providedIn: 'root' })
export class LanguageInitializationService extends ReactiveComponent {
  private readonly availableLanguages = ['en', 'de', 'fr'];

  constructor(
    private userService: UserService,
    private translate: TranslationHelper
  ) {
    super();
  }

  public init(): void {
    // Initialize the translations in the beginning (this loads additional translations, which might be needed to display error messages during application startup)
    const defaultLanguage = this.availableLanguages.includes(this.translate.browserLocale) ? this.translate.browserLocale : 'en';
    this.translate.init(defaultLanguage, ['en', 'de', 'fr'], defaultLanguage);
    this.userService.currentUser$
      .pipe(
        isTruthy(),
        take(1),
        map(user => user.locale)
      )
      .subscribe(language => this.translate.setLanguage(language));

    this.translate
      .currentLocaleStream()
      .pipe(
        skip(1),
        switchMap(locale => this.userService.changeLocale(locale)),
        takeUntil(this.unsubscribe$)
      )
      .subscribe();
  }
}
