import { AfterViewInit, Directive, ElementRef, Input } from '@angular/core';
import { fromEvent, merge } from 'rxjs';
import { pairwise, takeUntil } from 'rxjs/operators';

import { ReactiveComponent } from '@celum/ng2base';

@Directive({
             selector: '[swipe-scroll-handler]',
             standalone: true
           })
export class SwipeScrollHandlerDirective extends ReactiveComponent implements AfterViewInit {
  @Input() public reloadOnScrollToTop = false;

  constructor(private el: ElementRef) {
    super();
  }

  public ngAfterViewInit(): void {
    const hostEl = this.el.nativeElement;

    const touchStart$ = fromEvent<TouchEvent>(hostEl, 'touchstart');
    const touchMove$ = fromEvent<TouchEvent>(hostEl, 'touchmove');
    const touchCancel$ = fromEvent<TouchEvent>(hostEl, 'touchcancel');
    const touchEnd$ = fromEvent<TouchEvent>(hostEl, 'touchend');

    merge(touchStart$, touchMove$).pipe(pairwise(), takeUntil(this.unsubscribe$)).subscribe(([e1, e2]) => {
      const e1Touch = e1.changedTouches[0];
      const e2Touch = e2.changedTouches[0];
      const deltaX = Math.abs(e1Touch.screenX - e2Touch.screenX);
      const deltaY = Math.abs(e1Touch.screenY - e2Touch.screenY);

      const proto: TouchEvent | PointerEvent = Object.getPrototypeOf(e2);

      // user swipes horizontally or it is the starting event
      if (deltaX > deltaY || e2.type === 'touchstart') {
        // HACK this prevents perfect-scrollbar from preventing horizontal scrolling on the page while swiping in the scrollbar's container area
        // TouchEvent's prototype is modified, because the events handled by this piece of code and by perfect-scrollbar have different references
        // issue: https://github.com/utatti/perfect-scrollbar/issues/801
        proto.preventDefault = () => { /* nothing to do here */ };
      } else if(!this.reloadOnScrollToTop) {
        // HACK cleanup preventDefault dummy
        if (proto.hasOwnProperty('preventDefault')) {
          delete proto.preventDefault;
        }
        // prevent scrolling in the rest of the document and prevent reload on android
        e2.preventDefault();
      }
    });

    merge(touchCancel$, touchEnd$).pipe(takeUntil(this.unsubscribe$)).subscribe(e => {
      const proto = Object.getPrototypeOf(e) as TouchEvent;

      // HACK make sure on every touchend and touchcancel the preventDefault dummy is cleaned up
      if (proto.hasOwnProperty('preventDefault')) {
        delete proto.preventDefault;
      }
    });
  }
}
