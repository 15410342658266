import {
  ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, HostBinding, Input, OnChanges, OnInit, Output, SecurityContext, SimpleChanges
} from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { catchError, isObservable, of, take } from 'rxjs';

import { ExecutableAction } from '@celum/core';
import { TranslationHelper } from '@celum/ng2base';

import { SnackbarConfiguration, SnackbarEntry, SnackbarState, SnackbarStateDependentActions } from './snackbar-configurations';
import { ColorConstants } from '../../../base/color-constants';
import { IconConfiguration } from '../../../icon';

@Component({
             selector: 'snackbar',
             templateUrl: './snackbar.html',
             styleUrls: ['./snackbar.less'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class Snackbar implements OnInit, OnChanges {

  @Input() public configuration: SnackbarConfiguration;

  @Output() public readonly snackbarDismissed: EventEmitter<void> = new EventEmitter<void>();
  @Output() public readonly snackbarToggled: EventEmitter<boolean> = new EventEmitter<boolean>();

  @HostBinding('class.snackbar') public hostCls = true;

  public expanded = false;
  public header = '';
  public description = '';
  public htmlHeader: SafeHtml = null;
  public htmlDescription: SafeHtml = null;
  public toggleIcon: IconConfiguration;
  public dismissIcon: IconConfiguration = IconConfiguration.small('cancel-s', '').withColor('#ffffff').withIconSize(22);

  public currentActions: ExecutableAction[] = [];

  private autoVanishTimer: number;

  constructor(private changeDetector: ChangeDetectorRef, private sanitizer: DomSanitizer, private translate: TranslateService,
              private translationHelper: TranslationHelper) {
  }

  public ngOnInit(): void {
    this.translate.onLangChange.subscribe(() => this.evaluateName());
  }

  public ngOnChanges(changes: SimpleChanges): void {
    if (changes && changes.configuration && Snackbar.hasConfigChanged(changes.configuration.previousValue, changes.configuration.currentValue)) {
      this.modifyIcon();
      this.evaluateName();
      this.currentActions = Snackbar.evaluateActions(this.configuration.actions, this.configuration.status);
      this.toggleIcon = IconConfiguration.small('arrow-up-xs', '').withColor('#ffffff').withIconSize(this.configuration.expandIconSize);

      this.autoVanishTimer && clearTimeout(this.autoVanishTimer);
      if (this.configuration.autoVanish) {
        this.autoVanishTimer = window.setTimeout(() => {
          this.dismiss();
          this.configuration.onVanishCallback && this.configuration.onVanishCallback();
        }, this.configuration.autoVanishTime);
      }
    }
  }

  public toggle(): void {
    this.configuration.expandable && this.snackbarToggled.emit(!this.configuration.expanded);
  }

  public dismiss(): void {
    const dismissCallback$ = this.configuration.onDismissCallback?.();

    isObservable(dismissCallback$) ? dismissCallback$.pipe(take(1), catchError(error => {
      console.warn('Snackbar: error in dismiss callback. Assuming no veto.', error);
      return of(true);
    })).subscribe(dismiss => dismiss && this.snackbarDismissed.emit()) : this.snackbarDismissed.emit();
  }

  public modifyIcon(): void {
    if (this.configuration.icon && this.configuration.autoModifyIconColor) {
      const colors = {
        [SnackbarState.SUCCESS]: ColorConstants.SYSTEM_GREEN,
        [SnackbarState.WARNING]: ColorConstants.WARNING,
        [SnackbarState.ERROR]: ColorConstants.SYSTEM_RED,
        [SnackbarState.PREPARATION]: ColorConstants.PRIMARY,
        [SnackbarState.PROGRESS]: ColorConstants.PRIMARY
      };

      const color = colors[this.configuration.status] || ColorConstants.PRIMARY;
      this.configuration.icon = IconConfiguration.clone(this.configuration.icon).withColor(color).withIconSize(20);
    }
  }

  private evaluateName(): void {
    const headers = this.evaluateSnackbarEntries(this.configuration.titles);
    const descriptions = this.evaluateSnackbarEntries(this.configuration.descriptions);

    const headerText = headers.reduce((acc, next) => acc.concat(next + ' '), '');
    this.header = headerText;
    this.htmlHeader = this.sanitizer.sanitize(SecurityContext.HTML, headerText);

    const descriptionText = descriptions.reduce((acc, next) => acc.concat(next + ' '), '');
    this.description = descriptionText;
    this.htmlDescription = this.sanitizer.sanitize(SecurityContext.HTML, descriptionText);

    this.changeDetector.markForCheck();
  }

  private evaluateSnackbarEntries(entries: SnackbarEntry[]): string[] {
    const currentLang = this.translate.currentLang;
    return entries.map(entry => this.translationHelper.instant(entry.title, currentLang, this.translate.defaultLang, currentLang, true, entry.titleParams));
  }

  public static hasConfigChanged(previous: SnackbarConfiguration, current: SnackbarConfiguration): boolean {
    // return if status of snackbar or title has changed
    if (previous && current) {
      return JSON.stringify(previous) !== JSON.stringify(current);
    } else {
      return true;
    }
  }

  private static evaluateActions(dependentActions: SnackbarStateDependentActions, state: SnackbarState): ExecutableAction[] {
    const actionsAreDefined = dependentActions && dependentActions[state];
    return actionsAreDefined ? dependentActions[state] : [];
  }
}
