import {
  ChangeDetectorRef, ComponentRef, EventEmitter, OnChanges, SimpleChange, SimpleChanges, Type, ViewContainerRef
} from '@angular/core';
import { NgElement, WithProperties } from '@angular/elements';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { DataUtil } from '@celum/core';

/** Represents an angular element HTML element with the properties of the according angular component. */
export type ElementTag<NG_COMPONENT> = NgElement & WithProperties<NG_COMPONENT>;

type ExtractEmitterType<T> = T extends EventEmitter<infer V> ? V : never;
type OnlyKeysOfType<BASE, T> = {
  [K in keyof BASE]?: K extends string ? (BASE[K] extends T ? K : never) : never
}[keyof BASE];

/**
 * Util class containing static helper functions for creating and updating components (component classes and angular elements as well).
 * Mainly used for extensions.
 */
export class ComponentCreatorUtil {

  /**
   * Creates the given component in the provided placeHolder using the provided resolver.
   *
   * @param placeHolder     the parent component to create the component in
   * @param componentClass  the component to create
   * @param index           component will be added as last element when nothing is specified
   * @returns ComponentRef  the ComponentRef instance for the created component or null if creation failed
   */
  public static createComponent<T>(placeHolder: ViewContainerRef, componentClass: Type<T>,
                                   index?: number): ComponentRef<T> {
    const component = placeHolder.createComponent(componentClass, { index });

    // eslint-disable-next-line eqeqeq
    if (component == null) {
      console.error('ComponentCreatorUtil: Could not create component ' + componentClass);
      return null;
    }

    return component;
  }

  /**
   * Create a new tag for the angular element with the given `tagName` and append it to the given `parentNode`.
   *
   * @param tagName         element to create
   * @param parentNode      the parent node to append the created element to
   * @param insertPosition  where to insert the extension into the provided `parentNode`. Either provide an index or simply `'first'` or `'last'` for the first
   *   or last position in the children array
   */
  public static createAngularElement<C>(tagName: string, parentNode: HTMLElement,
                                        insertPosition: 'last' | 'first' | number = 'last'): NgElement & WithProperties<C> {
    const element = document.createElement(tagName) as NgElement & WithProperties<C>;

    const insertLast = (insertPosition === 'first' && parentNode.childNodes.length === 0) || insertPosition === parentNode.childNodes.length;

    if (insertPosition === 'last' || insertLast) {
      parentNode.appendChild(element);
    } else if (insertPosition === 'first') {
      parentNode.insertBefore(element, parentNode.childNodes.item(0));
    } else {
      parentNode.insertBefore(element, parentNode.childNodes.item(insertPosition));
    }

    return element;
  }

  /**
   * @deprecated Please use {@link updateExtensionProperties}. Will be removed with 22.9.
   *
   * Update the values of a dynamically created component.
   *
   * @param property              the name of the property to update
   * @param newValue              the new value
   * @param component             the component to update
   * @param customChangeDetector  (optional) a custom changeDetectorRef that should be used instead of the componentInstance ChangeDetectorRef
   */
  public static updateComponentProperty<T, K extends Extract<keyof T, string>>(property: K, newValue: T[K], component: ComponentRef<T>,
                                                                               customChangeDetector?: ChangeDetectorRef): void {
    ComponentCreatorUtil.updateComponentProperties([
                                                     {
                                                       propertyName: property,
                                                       current: newValue,
                                                       previous: component.instance[property]
                                                     }
                                                   ], component, customChangeDetector);
  }

  public static updateExtensionProperties<T, E extends ElementTag<T>>(element: E | ComponentRef<T>, values: Partial<T>,
                                                                      customChangeDetector?: ChangeDetectorRef): boolean {
    if (!element) {
      console.error(`ComponentCreatorUtil: Invalid input values, need component to update extension properties!`);
      return false;
    }

    const updatable = element instanceof ComponentRef ? element.instance : element;
    const componentRef: ComponentRef<T & OnChanges> = element instanceof ComponentRef && element as any; // force componentRef to know "OnChanges"

    const changes: SimpleChanges = {};

    Object.entries(values).forEach(value => {
      const property = value[0] as keyof T; // typing gets lost
      const newValue = value[1] as any;

      const previousValue = updatable[property];

      updatable[property] = newValue;

      if (previousValue !== newValue) {
        // eslint-disable-next-line eqeqeq
        changes[property as string] = new SimpleChange(previousValue, newValue, previousValue == null);
      }
    });

    // in case of a component ref, we have to take care of calling ngOnChanges manually
    const hasChanges = !DataUtil.isEmpty(changes);

    if (componentRef?.instance?.ngOnChanges && hasChanges) {
      componentRef.instance.ngOnChanges(changes);

      if (customChangeDetector) {
        customChangeDetector.markForCheck();
      } else {
        const componentChangeDetector = componentRef.injector.get(ChangeDetectorRef);
        componentChangeDetector?.markForCheck();
      }
    }

    return hasChanges;
  }

  public static listenToExtensionOutput<T, P extends OnlyKeysOfType<T, EventEmitter<any>>>(element: ElementTag<T> | ComponentRef<T>,
                                                                                           property: P): Observable<ExtractEmitterType<T[P]>> | T[P] {
    if (element instanceof ComponentRef) {
      return element.instance[property];
    } else {
      return fromEvent<CustomEvent>(element, property).pipe(map(event=> event.detail));
    }
  }

  /**
   * @deprecated Please use {@link updateExtensionProperties}. Will be removed with 22.9.
   *
   * Update the input values of a dynamically created component.
   *
   * @param propertiesToUpdate    a list of objects containing information on how to update a specific property
   * @param component             the component to update
   * @param customChangeDetector (optional) a custom changeDetectorRef that should be used instead of the componentInstance ChangeDetectorRef
   */
  public static updateComponentProperties(propertiesToUpdate: UpdateComponentPropsFieldUpdate[], component: ComponentRef<any>,
                                          customChangeDetector?: ChangeDetectorRef): void {
    propertiesToUpdate.forEach(property => component.instance[property.propertyName] = property.current);

    // Angular only calls ngOnChanges when binding via template, so we have to call it manually
    if (component.instance.ngOnChanges) {
      const changes: SimpleChanges = {};
      propertiesToUpdate.forEach(property => changes[property.propertyName] = new SimpleChange(property.previous, property.current, !property.previous));
      component.instance.ngOnChanges(changes);
    }

    if (customChangeDetector) {
      customChangeDetector.markForCheck();
    } else {
      const componentChangeDetector = component.injector.get(ChangeDetectorRef);
      componentChangeDetector?.markForCheck();
    }
  }
}

export interface UpdateComponentPropsFieldUpdate {
  propertyName: string;
  current: any;
  previous: any;
}
