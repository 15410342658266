import { ColorConstants } from '../base/color-constants';
import { IconConfiguration } from '../icon';

export enum AVATAR_SIZE {
  s = 20,
  m = 30,
  l = 40,
  xl = 80,
  xxxl = 160
}

export enum TEXT_SIZE {
  s = 8,
  m = 12,
  l = 16,
  xl = 30,
  xxxl = 60
}

export enum LABEL_POSITION {
  TOP_LEFT = 0,
  BOTTOM_RIGHT = 1
}

// we can't use icon configuration as we have to maintain - at least - icon size ourselves!
export class AvatarLabel {
  public position: LABEL_POSITION;

  /**
   * @param icon            icon to show for the label
   * @param title           title to show when hovering over the label
   * @param backgroundColor background color to use
   */
  constructor(public icon: string, public title: string, public backgroundColor?: string) {}

  // should be done by avatar config
  public setPosition(position: LABEL_POSITION): void {
    this.position = position;
  }
}

// @dynamic
export class AvatarConfiguration {
  public static INACTIVE_BG_COLOR = 'rgba(0,0,0,0.3)';

  public image = '';
  public icon: IconConfiguration;
  public size: number | AVATAR_SIZE = AVATAR_SIZE.m;
  public fontSize: number;
  public title = '';
  public titleLength: number;
  public cssClass = '';
  public backgroundColor = '#666666';
  public showOverlay = false;
  public overlayColor: string;
  public overlayIcon: IconConfiguration;
  public inactive = false;
  public borderColor = '';
  public displayText = '';
  public displayName = false;
  public useHtmlTitleAttribute = true;
  public labels: AvatarLabel[] = [];
  public email = '';
  public tooltip = '';
  public tooltipTranslateParams: { [key: string]: string } = {};
  public avatarTextPlaceholder: string = undefined;
  public textColor: string;
  public opacity = 1;

  public addTopLeftLabel(label: AvatarLabel): void {
    label.setPosition(LABEL_POSITION.TOP_LEFT);
    this.labels.push(label);
  }

  public addBottomRightLabel(label: AvatarLabel): void {
    label.setPosition(LABEL_POSITION.BOTTOM_RIGHT);
    this.labels.push(label);
  }

  public addOverlayIcon(icon: IconConfiguration, color?: string): void {
    this.showOverlay = true;
    this.overlayIcon = icon;
    this.overlayColor = color ? color : ColorConstants.PRIMARY;
  }
}

export class AvatarConfigBuilder<T extends AvatarConfiguration> {
  protected config: T;

  constructor() {
    this.config = this.createConfig();
  }

  public placeHolder(size?: number | AVATAR_SIZE, fontSize?: number | TEXT_SIZE): AvatarConfigBuilder<T> {
    this.withTitle('...')
      .withSize(size || AVATAR_SIZE.s)
      .withFontSize(fontSize || TEXT_SIZE.s)
      .withTitleLength(3)
      .withHtmlTitleAttribute(false)
      .withBackgroundColor(AvatarConfiguration.INACTIVE_BG_COLOR);

    return this;
  }

  public of(image: string, title: string, size: number | AVATAR_SIZE): AvatarConfigBuilder<T> {
    this.withImage(image).withTitle(title).withSize(size);
    return this;
  }

  public small(image: string, title: string): AvatarConfigBuilder<T> {
    this.withImage(image).withTitle(title).withSize(AVATAR_SIZE.s);
    return this;
  }

  public medium(image: string, title: string): AvatarConfigBuilder<T> {
    this.withImage(image).withTitle(title).withSize(AVATAR_SIZE.m);
    return this;
  }

  public large(image: string, title: string): AvatarConfigBuilder<T> {
    this.withImage(image).withTitle(title).withSize(AVATAR_SIZE.l);
    return this;
  }

  public xxxLarge(image: string, title: string): AvatarConfigBuilder<T> {
    this.withImage(image).withTitle(title).withSize(AVATAR_SIZE.xxxl);
    return this;
  }

  public withImage(image: string): AvatarConfigBuilder<T> {
    this.config.image = image;
    return this;
  }

  public withTitle(iconTitle: string): AvatarConfigBuilder<T> {
    this.config.title = iconTitle;
    return this;
  }

  public withTitleLength(iconTitleLength: number): AvatarConfigBuilder<T> {
    this.config.titleLength = +iconTitleLength;
    return this;
  }

  public withSize(size: number | AVATAR_SIZE): AvatarConfigBuilder<T> {
    this.config.size = +size;
    return this;
  }

  public withIcon(icon: IconConfiguration): AvatarConfigBuilder<T> {
    this.config.icon = icon;
    return this;
  }

  public withAvatarTextPlaceholder(avatarTextPlaceholder: string): AvatarConfigBuilder<T> {
    this.config.avatarTextPlaceholder = avatarTextPlaceholder;
    return this;
  }

  /**
   * @deprecated
   * This property is not supported anymore.
   */
  public withFontSize(fontSize: number): AvatarConfigBuilder<T> {
    this.config.fontSize = +fontSize;
    return this;
  }

  public withBackgroundColor(bg: string): AvatarConfigBuilder<T> {
    this.config.backgroundColor = bg;
    return this;
  }

  public withClass(clazz: string): AvatarConfigBuilder<T> {
    this.config.cssClass = clazz;
    return this;
  }

  public withOverlayIcon(icon: IconConfiguration, color?: string): AvatarConfigBuilder<T> {
    this.config.addOverlayIcon(icon, color);
    return this;
  }

  public withTopLeftLabel(label: AvatarLabel): AvatarConfigBuilder<T> {
    this.config.addTopLeftLabel(label);
    return this;
  }

  public withBottomRightLabel(label: AvatarLabel): AvatarConfigBuilder<T> {
    this.config.addBottomRightLabel(label);
    return this;
  }

  public withHtmlTitleAttribute(show: boolean): AvatarConfigBuilder<T> {
    this.config.useHtmlTitleAttribute = show;
    return this;
  }

  public withDisplayName(name: string, display = true): AvatarConfigBuilder<T> {
    this.config.displayText = name;
    this.config.displayName = display;

    return this;
  }

  public withTooltip(tooltip: string, translateParams?: { [key: string]: string }): AvatarConfigBuilder<T> {
    this.config.tooltip = tooltip;
    this.config.tooltipTranslateParams = translateParams;
    return this;
  }

  public withTextColor(textColor: string): AvatarConfigBuilder<T> {
    this.config.textColor = textColor;
    return this;
  }

  public withDisplayText(displayText: string): AvatarConfigBuilder<T> {
    this.config.displayText = displayText;
    return this;
  }

  public isInactive(inactive: boolean): AvatarConfigBuilder<T> {
    this.config.inactive = inactive;
    return this;
  }

  public withBorderColor(borderColor: string): AvatarConfigBuilder<T> {
    this.config.borderColor = borderColor;
    return this;
  }

  public withOpacity(opacity: number): AvatarConfigBuilder<T> {
    this.config.opacity = opacity;
    return this;
  }

  public build(): T {
    return this.config;
  }

  protected createConfig(): T {
    return new AvatarConfiguration() as any as T;
  }
}
