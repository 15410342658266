import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { EMPTY, Observable } from 'rxjs';
import { catchError, map, mergeMap, switchMap, take } from 'rxjs/operators';

import { GroupInfo, OperationDefinition } from '@celum/core';

import { MagicButtonActionTypes, UpdateContextAction, UpdateOperationsAction } from './magic-button-actions';
import { getContext } from './magic-button-reducers';
import { MagicButtonService } from '../services/magic-button.service';

@Injectable()
export class MagicButtonEffects {

  public contextChanged$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MagicButtonActionTypes.UpdateContextAction),
    switchMap((action: UpdateContextAction) => this.magicButtonService.collectAllOperations(action.context)
                                                   .pipe(map(({ featured, operations }) => {
                                                           return new UpdateOperationsAction(featured.map(op => MagicButtonEffects.operationToStoreOperation(op)),
                                                                                             operations.map(op => MagicButtonEffects.operationToStoreOperation(op)));
                                                         })
                                                   )
    ),
    catchError(error => {
      console.error('MagicButtonEffects: Failed to retrieve current operations', error);
      return EMPTY;
    })
  ));

  public evaluateOperations$: Observable<Action> = createEffect(() => this.actions$.pipe(
    ofType(MagicButtonActionTypes.EvaluateOperationsAction),
    mergeMap(() => this.store.select(getContext).pipe(take(1), map(currentContext => new UpdateContextAction(currentContext))))
  ));

  constructor(private actions$: Actions, private store: Store<any>, private magicButtonService: MagicButtonService) {
  }

  private static operationToStoreOperation(operationDef: OperationDefinition): { operationKey: string, priority: number, groupInfo: GroupInfo } {
    return {
      operationKey: operationDef.operation.getKey(),
      priority: operationDef.priority,
      groupInfo: operationDef.groupInfo
    };
  }
}
