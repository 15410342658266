import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewChild } from '@angular/core';
import { MatButtonModule } from '@angular/material/button';
import { MatMenuModule, MatMenuTrigger } from '@angular/material/menu';
import { MatTooltipModule } from '@angular/material/tooltip';
import { Store } from '@ngrx/store';
import { BehaviorSubject, combineLatest, map, Observable } from 'rxjs';

import { UserService } from '@celum/authentication';
import { CelumAvatarListModule, CelumAvatarModule, CelumButtonModule, CelumIconModule, ColorConstants, IconConfiguration } from '@celum/common-components';
import { LibrariesProperties, LibraryErrorKey, LibraryStatus } from '@celum/libraries/domain';
import { Library } from '@celum/libraries/shared';
import { CelumDirectivesModule } from '@celum/ng2base';
import { AccountUser } from '@celum/shared/domain';
import { UserAvatarComponent } from '@celum/shared/ui-people';
import { ContextMenuHandlerDirective } from '@celum/shared/util';

export interface LibraryListRowViewModel {
  isLibraryOwner: boolean;
  isLibraryOwnerActive: boolean;
  library: Library;
  creator: Observable<AccountUser>;
  isProd: boolean;
  canOpenLibraryPreview: boolean;
}

@Component({
  standalone: true,
  changeDetection: ChangeDetectionStrategy.OnPush,
  selector: 'libs-library-list-row',
  imports: [
    CommonModule,
    MatButtonModule,
    MatMenuModule,
    MatTooltipModule,

    CelumAvatarModule,
    CelumAvatarListModule,
    CelumButtonModule,
    CelumDirectivesModule,
    CelumIconModule,
    ContextMenuHandlerDirective,
    UserAvatarComponent
  ],
  templateUrl: './library-list-row.component.html',
  styleUrls: ['./library-list-row.component.scss']
})
export class LibraryListRowComponent {
  @Output()
  public readonly changeStatusOfLibrary = new EventEmitter<LibraryStatus>();

  @Output()
  public readonly deleteLibrary = new EventEmitter<Library>();

  @Output()
  public readonly editLibrary = new EventEmitter<Library>();

  @Output()
  public readonly previewLibrary = new EventEmitter<Library>();

  @Output()
  public readonly openJSON = new EventEmitter<Library>();

  @ViewChild(MatMenuTrigger) protected menu: MatMenuTrigger;

  protected vm$: Observable<LibraryListRowViewModel>;

  protected icons = {
    activeLibraryIcon: IconConfiguration.medium('libraries-main-logo').withColor(ColorConstants.STANDARD_BLACK).withIconSize(25),
    inactiveLibraryIcon: IconConfiguration.medium('archived-m').withColor(ColorConstants.STANDARD_BLACK),
    filterIcon: IconConfiguration.medium('filter-m').withColor(ColorConstants.STANDARD_BLACK),
    errorIcon: IconConfiguration.medium('error-circle-m').withColor(ColorConstants.SYSTEM_RED),
    inactiveIconSmall: new IconConfiguration('archived-m').withColor(ColorConstants.STANDARD_BLACK).withIconSize(16),
    deleteIcon: IconConfiguration.medium('remove').withColor(ColorConstants.STANDARD_BLACK).withIconSize(16),
    activateIcon: IconConfiguration.medium('key').withColor(ColorConstants.STANDARD_BLACK).withIconSize(16),
    editIcon: IconConfiguration.medium('edit-lib').withColor(ColorConstants.STANDARD_BLACK).withIconSize(16),
    codeIcon: IconConfiguration.medium('code').withColor(ColorConstants.STANDARD_BLACK).withIconSize(16)
  };

  protected LibraryStatus = LibraryStatus;

  private librarySubject = new BehaviorSubject<Library>(null);

  constructor(
    protected store: Store,
    protected userService: UserService
  ) {
    this.vm$ = combineLatest([userService.currentUser$, this.librarySubject]).pipe(
      map(([currentUser, library]) => {
        const isLibraryOwner = currentUser?.oid === library?.createdById;
        const isLibraryOwnerActive = library?.error?.key !== LibraryErrorKey.USER_INACTIVE;

        return {
          library,
          isLibraryOwner,
          isLibraryOwnerActive,
          creator: library?.createdBy(store),
          isProd: LibrariesProperties.properties.apiUrl.includes('celum.com'),
          canOpenLibraryPreview: isLibraryOwner && isLibraryOwnerActive && library.status === LibraryStatus.ACTIVE
        };
      })
    );
  }

  @Input()
  public set library(value: Library) {
    this.librarySubject.next(value);
  }
}
