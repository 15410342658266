<div class="magic-button">

  <div class="magic-button_button" [attr.component-id]="'magic-button'" [class.magic-button_button--disabled]="disabled" (click)="onOpenClick()">
    <div class="magic-button_bg"></div>
    <div class="magic-button_color" [style.background]="disabled ? disabledColor : buttonColor" [class.magic-button_color--disabled]="disabled">
      <celum-icon [configuration]="buttonIcon"></celum-icon>
    </div>
  </div>

  <celum-magic-button-entry *ngFor="let item of operations"
                            [currentDataContext]="currentDataContext"
                            [operation]="item.operation"
                            (buttonClicked)="operationClicked(item)"></celum-magic-button-entry>
</div>
