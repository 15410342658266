import { AsyncPipe, NgForOf, NgIf } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, Input, Output, ViewEncapsulation } from '@angular/core';
import { MatChipsModule } from '@angular/material/chips';
import { TranslateModule } from '@ngx-translate/core';

import { CelumIconModule } from '@celum/common-components';

@Component({
  selector: 'celum-chip-set',
  templateUrl: './chip-set.component.html',
  styleUrls: ['./chip-set.component.scss'],
  standalone: true,
  imports: [CelumIconModule, MatChipsModule, NgForOf, TranslateModule, NgIf, AsyncPipe],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class CelumChipSetComponent {
  @Input() public showClearAll = true;
  @Output() public clearAllClicked = new EventEmitter<true>();
}
