<lookup-area
  *ngIf="!hideSearchBox"
  class="search-and-select_lookup"
  data-test-lookup-area
  tabindex="1"
  [debounceTime]="searchDebounceTime"
  [formFieldSize]="'medium'"
  [placeholder]="searchBoxPlaceholderText | translate"
  [value]="searchValue"
  (click)="$event.stopPropagation()"
  (keyEvent)="$event.stopPropagation()"
  (searchValue)="searchValueChanged.emit($event)"></lookup-area>

<ng-container *ngIf="!loadingItems && items.length">
  <celum-simple-list
    class="search-and-select_items"
    data-test-search-and-select-items
    [class.multi]="multipleSelection"
    [clearSelectionOnEmptySpaceClick]="clearSelectionOnEmptyClick"
    [compareFn]="compareFn"
    [fetchingNextBatch]="loadingMoreItems"
    [hasMoreBottom]="hasMoreBottom"
    [items]="items"
    [itemTemplate]="listItemTemplate"
    [selectionHandler]="listSelectionHandler"
    [stopClickEventPropagation]="true"
    (requestNextPage)="requestNextPage.next($event)">
    <ng-container bottom-item *ngTemplateOutlet="bottomItem"></ng-container>
  </celum-simple-list>

  <ng-template let-item #listItemTemplate>
    <ng-container *ngIf="{ isInSelection: listSelectionHandler.isInSelection(item), isDisabled: listSelectionHandler.isDisabled(item) } as data">
      <div class="search-and-select_item" [ngClass]="{ 'search-and-select_item--disabled': data.isDisabled }" data-test-simple-item>
        <div class="search-and-select_item_data">
          <ng-container *ngTemplateOutlet="itemTemplate; context: { $implicit: item }"></ng-container>
        </div>
        <div
          *ngIf="multipleSelection"
          class="search-and-select_item-checkbox"
          [matTooltip]="disabledCheckboxTooltip | translate"
          [matTooltipDisabled]="!data.isDisabled"
          [matTooltipPosition]="'below'">
          <mat-checkbox data-test-checkbox-item [checked]="data.isInSelection" [disabled]="data.isDisabled"></mat-checkbox>
        </div>
      </div>
    </ng-container>
  </ng-template>
</ng-container>

<empty-page
  *ngIf="!loadingItems && !items.length"
  class="search-and-select_empty-list"
  [config]="noItemFoundIcon | wrapFn: getEmptyPageConfig : noItemText : noItemFoundIconSize : noItemFoundIconColor"></empty-page>

<loading-mask *ngIf="loadingItems" class="search-and-select_loading"></loading-mask>
