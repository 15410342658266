import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material/dialog';
import { from } from 'rxjs';

import { Library } from '@celum/libraries/shared';

@Injectable({ providedIn: 'root' })
export class LibraryWizardApi {
  constructor(private dialog: MatDialog) {}

  /**
   * Open the Library Wizard
   * @param library optional: pre-fill designer with data from existing library
   */
  public open(library?: Library) {
    return from(
      import('./library-wizard.component').then(component =>
        this.dialog.open(component.LibraryWizardComponent, {
          width: '100vw',
          height: '100vh',
          maxWidth: '100vw',
          data: library
        })
      )
    );
  }
}
