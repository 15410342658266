import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';
import { TranslateModule } from '@ngx-translate/core';

import { UserService } from '@celum/authentication';
import { AVATAR_SIZE, CelumDialogModule } from '@celum/common-components';
import { AccountUser } from '@celum/shared/domain';
import { UserAvatarComponent } from '@celum/shared/ui-people';

export interface ContactDialogConfiguration {
  headerText: string;
  user: Partial<AccountUser>;
  text: string;
  confirmButtonText: string;
}

@Component({
  standalone: true,
  imports: [CommonModule, TranslateModule, CelumDialogModule, UserAvatarComponent],
  selector: 'libs-contact-dialog',
  templateUrl: './contact-dialog.component.html',
  styleUrls: ['./contact-dialog.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class ContactDialogComponent {
  protected selectedAccount$ = this.userService.accountAccessForCurrentTenant$;

  protected readonly AVATAR_SIZE = AVATAR_SIZE;

  constructor(
    protected dialogRef: MatDialogRef<ContactDialogComponent, boolean>,
    @Inject(MAT_DIALOG_DATA) protected config: ContactDialogConfiguration,
    private userService: UserService
  ) {}

  protected contact(email: string): void {
    window.location.href = `mailto:${email}`;
    this.dialogRef.close(true);
  }
}
