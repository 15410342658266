// eslint-disable-next-line max-classes-per-file
import { Action } from '@ngrx/store';

export enum TreeActionTypes {
  ChangeRootNodeSelection = '[TreeAction] Change Root Node Selection',
  Collapse = '[TreeAction] Collapse Node',
  Expand = '[TreeAction] Expand Node',
  SelectNode = '[TreeAction] Select Node',
  NrOfRootNodes = '[TreeAction] Update Number Of Root Nodes',
  RootNodesLoaded = '[TreeAction] Root Nodes Loaded',
  AddChildrenForNodes = '[TreeAction] Add Children For Nodes',
  ReplaceChildrenForNode = '[TreeAction] Set Children For Nodes',
  RegisterTree = '[TreeAction] Register Tree',
  ResetTrees = '[TreeAction] Reset Trees',
  ResetTree = '[TreeAction] Reset Tree'
}

export abstract class TreeAction implements Action {
  public abstract type: string;

  constructor(public treeId: string) {
  }
}

export class RegisterTree extends TreeAction {
  public readonly type = TreeActionTypes.RegisterTree;

  constructor(treeId: string) {
    super(treeId);
  }
}

export class CollapseNode extends TreeAction {
  public readonly type = TreeActionTypes.Collapse;

  constructor(public treeId: string, public nodeId: string | string[]) {
    super(treeId);
  }
}

export class ExpandNode extends TreeAction {
  public readonly type = TreeActionTypes.Expand;

  constructor(public treeId: string, public nodeId: string | string[]) {
    super(treeId);
  }
}

export class SelectNode extends TreeAction {
  public readonly type = TreeActionTypes.SelectNode;

  constructor(public treeId: string, public nodeId: string) {
    super(treeId);
  }
}

export class ChangeRootNodeSelection extends TreeAction {
  public readonly type = TreeActionTypes.ChangeRootNodeSelection;

  constructor(public treeId: string, public selected: boolean) {
    super(treeId);
  }
}

export class NrOfRootNodes extends TreeAction {
  public readonly type = TreeActionTypes.NrOfRootNodes;

  constructor(public treeId: string, public nrOfRootNodes: number) {
    super(treeId);
  }
}

export class RootNodesLoaded extends TreeAction {
  public readonly type = TreeActionTypes.RootNodesLoaded;

  constructor(public treeId: string, public rootNodeIds: string[]) {
    super(treeId);
  }
}

export class AddChildrenForNodes implements Action {
  public readonly type = TreeActionTypes.AddChildrenForNodes;

  constructor(public parentId: string, public childrenIds: string[]) {
  }
}

export class ReplaceChildrenForNode implements Action {
  public readonly type = TreeActionTypes.ReplaceChildrenForNode;

  constructor(public parentId: string, public childrenIds: string[]) {
  }
}

export class ResetTrees implements Action {
  public readonly type = TreeActionTypes.ResetTrees;

}

export class ResetTree implements Action {
  public readonly type = TreeActionTypes.ResetTree;

  constructor(public treeId: string) {
  }

}

export type TreeActions =
  ChangeRootNodeSelection
  | CollapseNode
  | ExpandNode
  | SelectNode
  | NrOfRootNodes
  | RootNodesLoaded
  | AddChildrenForNodes
  | ReplaceChildrenForNode
  | RegisterTree
  | ResetTree
  | ResetTrees;
