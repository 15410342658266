<form class="library-details" [formGroup]="form">
  <div class="library-details_section">
    <div class="library-designer_header">{{ 'LIBRARIES.CREATE.DETAILS_SECTION' | translate }}</div>

    <div class="library-details_label" [class.library-details_label--invalid]="form.controls.name.invalid && form.controls.name.dirty">
      {{ 'LIBRARIES.CREATE.INPUT_NAME' | translate }}
    </div>

    <mat-form-field celum-form-field class="library-details_form-field" data-test-library-details-name [size]="'emphasized'" [reserveHintSpace]="true">
      <input
        cdkFocusInitial
        class="library-details_name-input"
        data-test-library-details-name-input
        formControlName="name"
        matInput
        maxLength="50"
        placeholder="{{ 'LIBRARIES.CREATE.INPUT_PLACEHOLDER' | translate }}"
        (focusout)="focusOut()" />
      <mat-hint>
        <celum-remaining-characters [control]="form.controls.name" ngDefaultControl></celum-remaining-characters>
      </mat-hint>
      <mat-error *ngIf="form.controls.name.hasError('required')">{{ 'LIBRARIES.CREATE.INPUT_NAME_ERROR' | translate }}</mat-error>
    </mat-form-field>

    <div class="library-details_row">
      <div class="library-details_permissions">
        <div class="library-details_label">{{ 'LIBRARIES.CREATE.PERMISSION_INFO' | translate }}</div>
        <celum-user-avatar
          *ngIf="creator"
          data-test-library-creator-avatar
          [accountAccessToken]="(userService.accountAccessForCurrentTenant$ | async)?.accountAccessToken"
          [showDisplayName]="true"
          [showTooltip]="false"
          [user]="creator"></celum-user-avatar>
        <celum-user-avatar
          *ngIf="!creator"
          data-test-library-creator-avatar
          [accountAccessToken]="(userService.accountAccessForCurrentTenant$ | async)?.accountAccessToken"
          [showDisplayName]="true"
          [showTooltip]="false"
          [user]="userService.currentUser$ | async"></celum-user-avatar>
      </div>

      <div class="library-details_repository">
        <div class="library-details_repository-label">
          {{ 'LIBRARIES.CREATE.REPOSITORY_SELECTION' | translate }}
          <celum-icon
            class="library-details_repository-icon"
            [configuration]="infoIconConfig"
            matTooltip="{{ 'LIBRARIES.CREATE.REPOSITORY_SELECTION_TOOLTIP' | translate }}"></celum-icon>
        </div>

        <div class="library-details_selected-repository">
          <span *ngIf="creator" class="library-details_repository-name" data-test-repository-selector>
            {{ form.controls.repositoryId.value }}
          </span>

          <libs-repository-selector *ngIf="!creator" [formControl]="form.controls.repositoryId"></libs-repository-selector>
        </div>
      </div>
    </div>
    <div class="library-details_connections" *ngIf="privateUsageStatistics?.count > 0">
      <div class="library-details_label library-details_connections_label">{{ 'LIBRARIES.CREATE.CONNECTIONS_TITLE' | translate }}</div>
      <div class="library-details_connections_text">
        <celum-icon [configuration]="experienceIconConfig"></celum-icon>
        <span [innerHTML]="'LIBRARIES.CREATE.PORTAL_USAGE' + usageStatisticsLanguageKeyEnding | translate: privateUsageStatistics | sanitized"></span>
      </div>
    </div>
  </div>
  <div class="library-details_section library-details_curators"></div>
</form>
