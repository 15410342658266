<ng-container *ngIf="service.vm$ | async as vm">
  <celum-icon
    class="libs-metadata-filter_add-metadata"
    cdkOverlayOrigin
    data-test-metadata-new-metadata-button
    #originOverlay="cdkOverlayOrigin"
    [configuration]="addNewMetadata"
    [matTooltip]="'LIBRARIES.CREATE.METADATA.ADD_METADATA_TOOLTIP' | translate"
    (click)="service.menuOpened(true)"></celum-icon>
  <ng-template
    cdkConnectedOverlay
    [cdkConnectedOverlayBackdropClass]="'cdk-overlay-transparent-backdrop'"
    [cdkConnectedOverlayHasBackdrop]="true"
    [cdkConnectedOverlayOpen]="vm.isOpen"
    [cdkConnectedOverlayOrigin]="originOverlay"
    [cdkConnectedOverlayPanelClass]="'mat-elevation-z3'"
    (overlayOutsideClick)="service.menuOpened(false)">
    <celum-search-and-select
      class="libs-metadata-filter_search-and-select"
      data-test-library-metadata-selector
      [itemTemplate]="itemTemplate"
      [items]="vm.filteredValues"
      [loadingItems]="vm.loading"
      [multipleSelection]="true"
      [noItemFoundIcon]="'no-metadata'"
      [noItemText]="'LIBRARIES.CREATE.METADATA.NO_METADATA_FOUND'"
      [searchBoxPlaceholderText]="'LIBRARIES.CREATE.METADATA.METADATA_SEARCH'"
      [searchValue]="vm.searchValue"
      [selectedItems]="vm.selectedValues"
      (itemSelectionChanged)="service.selectionChanged($event)"
      (searchValueChanged)="service.patchState({ searchValue: $event })"></celum-search-and-select>
  </ng-template>

  <ng-template #itemTemplate let-item>
    <span class="libs-metadata-filter_item checkbox" spaceAwareTooltip [matTooltip]="item.labels | language">{{ item.labels | language }}</span>
  </ng-template>
</ng-container>
