import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, HostBinding, Input, OnInit, ViewEncapsulation } from '@angular/core';
import { FormControl, FormGroup, FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatTooltipModule } from '@angular/material/tooltip';
import { TranslateModule } from '@ngx-translate/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { CelumIconModule, CelumLoadingMaskModule, CelumMessageModule, ColorConstants, EmptyPage, IconConfiguration } from '@celum/common-components';
import { AssetFilter, LibraryError } from '@celum/libraries/domain';
import { LibraryAssetsListComponent } from '@celum/libraries/ui';

import { LibraryAssetsFilterComponent } from './assets-filter/assets-filter.component';
import { AssetsStepService } from './assets-step.service';

@Component({
  standalone: true,
  imports: [
    CommonModule,
    MatTooltipModule,
    FormsModule,
    TranslateModule,
    CelumIconModule,
    CelumLoadingMaskModule,
    CelumMessageModule,
    LibraryAssetsFilterComponent,
    LibraryAssetsListComponent,
    InfiniteScrollModule,
    ReactiveFormsModule
  ],
  selector: 'libs-assets-step',
  templateUrl: './assets-step.component.html',
  styleUrls: ['./assets-step.component.scss', '../../styles/common.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  encapsulation: ViewEncapsulation.None
})
export class LibraryAssetsStepComponent implements OnInit {
  @HostBinding('class.libs-assets') public hostCls = true;

  @Input() public form: FormGroup<{
    filter: FormControl<AssetFilter | null>;
  }>;

  @Input() public repositoryId: string;

  @Input() public libraryError: LibraryError;

  protected readonly infoIcon = IconConfiguration.small('info-icon').withColor(ColorConstants.BLUE_GRAY_900).withIconSize(12);

  protected readonly noAssetsFoundFiltered = EmptyPage.noActionConfig(
    'no-assets-found-filter',
    'no-assets-found-filter',
    'LIBRARIES.CREATE.ASSETS.NO_ASSETS',
    'normal',
    232,
    'none'
  );
  protected readonly noAssetsNoFilterApplied = EmptyPage.noActionConfig(
    'no-filter-applied',
    'no-filter-applied',
    'LIBRARIES.CREATE.ASSETS.NO_FILTER_SELECTED',
    'normal',
    232,
    'none'
  );

  constructor(public service: AssetsStepService) {}

  public ngOnInit(): void {
    if (this.form.controls.filter.value) {
      this.service.init(this.repositoryId, this.form.controls.filter.value, this.libraryError);
    }

    this.form.controls.filter.valueChanges.subscribe(filter => {
      this.service.loadAssets(this.repositoryId, filter);
    });
  }
}
