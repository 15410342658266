<div class="libs-assets-list">
  <div *ngIf="assets.length !== 0; else noAssets" class="libs-assets-list_list">
    <libs-asset-card class="libs-assets-list_card" *ngFor="let asset of assets" [asset]="asset"></libs-asset-card>
    <span inViewport (visible)="lastItemIsVisible.next($event)"></span>
  </div>
  <div class="libs-assets-list_loading">
    <mat-spinner *ngIf="loading" [color]="'accent'" [diameter]="44" [strokeWidth]="4"></mat-spinner>
  </div>
  <ng-template #noAssets>
    <empty-page *ngIf="!loading" class="libs-assets-list_empty" [config]="emptyPageConfig"></empty-page>
  </ng-template>
</div>
