import { schema } from 'normalizr';

import { Entity, EntityRegistry, EntityTranslator } from '@celum/ng2base';

export const USAGE_STATISTICS = `UsageStatistics`;

export interface UsageStatistics extends Entity {
  id: string;
  published: number;
  active: number;
  inactive: number;
}

const usageStatisticsTranslator: EntityTranslator<UsageStatistics> = (usageStatistics: UsageStatistics) => ({
  id: usageStatistics.id,
  typeKey: USAGE_STATISTICS,
  published: usageStatistics.published,
  active: usageStatistics.active,
  inactive: usageStatistics.inactive
});

const usageStatisticsSchema = new schema.Entity<UsageStatistics>(USAGE_STATISTICS);

EntityRegistry.register<UsageStatistics>({
  typeKey: USAGE_STATISTICS,
  translator: usageStatisticsTranslator,
  schema: usageStatisticsSchema
});
