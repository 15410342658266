import { inject, Injectable } from '@angular/core';
import { Store } from '@ngrx/store';
import { normalize } from 'normalizr';
import { defer, Observable } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { IPaginationResult } from '@celum/core';

import { PAGINATION_TRANSLATOR_FN, PaginationTranslatorFn } from './pagination-translator';
import { Entity } from '../entity/entity';
import { EntityRegistry, ResultMetaInfo } from '../entity/entity-registry';
import { EntityActions } from '../entity-store/entity-actions';
import { selectEntitiesById } from '../entity-store/entity-state';

export interface Result<T, P = IPaginationResult> {
  /**
   * All entities that were defined as result entities by the schema. Supporting entities like relations (e.g. creator on asset) will not appear in this result
   * set but they are still added to the store and can be accessed via relations.
   */
  entities: T[];
  /**
   * Pagination information that was detected in the backend response.
   */
  paging: P;
}

/** representation of a normalizr result id. can be a string for simple use cases but also an object for things like union schemas */
type NormalizrResultId = string | { id: string, schema: string };

interface NormalizrResult {
  /** all entities that were found in the data that was given to normalizr */
  rawEntities: any[];
  /** the ids of the result as specified by the schema and normalizr */
  resultIds: string[];
}

/**
 * Type which can be used to customize the retrieval of data from the store.
 * Example: service.findOne(id).once -> get the entity from the backend and return once
 * Example: service.findOne(id).fresh -> get the entity from the backend and return a
 */
export type RetrievalOptions<T> = {
  /** Only emit the resulting entities once. There will be no updates in case the retrieved entities change. */
  once$: Observable<T>;
  /** Emit once initially and again whenever the entity was changed in the store. */
  fresh$: Observable<T>;
};

@Injectable({ providedIn: 'root' })
export class ResultConsumerService {

  private paginationTranslator = inject(PAGINATION_TRANSLATOR_FN, { optional: true }); // optional so we can log out a custom error message
  private store = inject(Store);

  /**
   * Consume a backend response object ({@param response$}) and process it according to the given {@param metaInfo}.
   * It will
   *  - normalize the response
   *  - put entities into the store
   *  - collect result entities from store
   *  - find pagination information
   */
  public consume<T extends Entity>(response$: Observable<any>, metaInfo: ResultMetaInfo): Observable<Result<T>> {
    return defer(() => response$).pipe(
      switchMap(rawData => {
        const { rawEntities, resultIds } = ResultConsumerService.normalize(rawData, metaInfo);
        const translatedEntities = ResultConsumerService.translateRawEntities(rawEntities, metaInfo);
        this.upsertEntitiesIntoStore(translatedEntities, metaInfo);

        const paging = ResultConsumerService.findPaginationInfo(rawData, metaInfo.paginationTranslator ?? this.paginationTranslator);
        const resultEntities$ = this.store.select(selectEntitiesById<T>(resultIds));

        return resultEntities$.pipe(map(entities => ({ entities, paging })));
      })
    );
  }

  /** Upsert entities to the store depending on the given meta information */
  private upsertEntitiesIntoStore(entities: Entity[], metaInfo: ResultMetaInfo): void {
    this.store.dispatch(EntityActions.upsertMany({ entities, deviations: metaInfo.deviation }));
  }

  /** Delegate to pagination injection token to find the paging info from the raw data. */
  private static findPaginationInfo(rawData: any, paginationTranslator: PaginationTranslatorFn): IPaginationResult {
    if (!paginationTranslator) {
      console.warn(
        `ResultConsumerService: Injection token "PAGINATION_STRATEGY_FN" was not found. It should provide a function that takes in raw data and translates it to the pagination information. Please provide one on application level. If not provided, no pagination information will be returned from the result consumer`);
    }

    if (!rawData) {
      return undefined;
    }

    return paginationTranslator?.(rawData);
  }

  /** Translate raw entity objects and transform them into real entities through the EntityRegistry */
  private static translateRawEntities(rawEntities: any[], metaInfo: ResultMetaInfo): Entity[] {
    return rawEntities.filter(entity => metaInfo.entityTypesToProcess?.has(entity.typeKey) ?? true)
                      .map(entity => EntityRegistry.get(entity.typeKey).translator(entity))
                      .filter(Boolean);
  }

  /** Pass raw data to normalizr */
  private static normalize(data: any, metaInfo: ResultMetaInfo): NormalizrResult {
    const dataToNormalize = metaInfo.resultKey ? data[metaInfo.resultKey] : data;
    const { entities, result } = normalize(dataToNormalize, metaInfo.schema);
    const rawEntities = Object.values(entities).map(entityById => Object.values(entityById)).flat();

    // result can be an id (only one entity), an array of ids (multiple entities) or an array of objects (multiple entities with union schema)
    const resultIds = typeof result === 'string' ? [result] : result.map((id: NormalizrResultId) => typeof id === 'object' ? id.id : id);
    return { rawEntities, resultIds };
  }

}
