import { DragDropModule } from '@angular/cdk/drag-drop';
import { ScrollingModule } from '@angular/cdk/scrolling';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { DragulaModule } from 'ng2-dragula';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { CelumButtonModule, CelumIconModule } from '@celum/common-components';
import { registerCustomizationType, registerExtensionType } from '@celum/core';
import { CelumDirectivesModule, CelumPipesModule, ClickTapDirective, HasOverflowDirective, LongPressDirective, TranslationModule } from '@celum/ng2base';

import { CelumList } from './components/celum-list/celum-list';
import { CelumDynamicListItem } from './components/dynamic-list-item';
import { InfiniteScrollListWrapperComponent } from './components/infinite-scroll-list-wrapper/infinite-scroll-list-wrapper.component';
import { ListLoadingSpinnerComponent } from './components/list-loading-spinner/list-loading-spinner.component';
import { CelumSimpleList } from './components/simple-list/simple-list';
import { CelumVirtualList } from './components/virtual-list/virtual-list';
import { OneCardItemCustomization } from './extension-point/card-item-customization';
import { CardItemExtension } from './extension-point/card-item-extension';
import { CelumDragAndDropModule } from '../drag-and-drop';

/**
 * ⚠ Requires dependencies `ngx-infinite-scroll` and `ng2-dragula`.
 */
@NgModule({
            imports: [
              CommonModule, ScrollingModule, InfiniteScrollModule, DragulaModule, TranslateModule, DragDropModule,

              CelumButtonModule, CelumIconModule, CelumDirectivesModule, CelumPipesModule, HasOverflowDirective, TranslationModule,

              CelumDragAndDropModule, ClickTapDirective, LongPressDirective, InfiniteScrollListWrapperComponent, ListLoadingSpinnerComponent
            ],
            declarations: [
              CelumList, CelumSimpleList, CelumDynamicListItem, CelumVirtualList
            ],
            exports: [
              CelumList, CelumSimpleList, TranslateModule, InfiniteScrollModule, DragulaModule, CelumVirtualList, CelumDynamicListItem
            ]
          })
export class CelumListModule {
  constructor() {
    registerExtensionType(CardItemExtension, CardItemExtension.type);
    registerCustomizationType(OneCardItemCustomization, OneCardItemCustomization.type);
  }
}
