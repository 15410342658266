import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { MatProgressSpinnerModule } from '@angular/material/progress-spinner';
import { TranslateModule } from '@ngx-translate/core';

import { SpinnerView } from './spinner-view';

@NgModule({
            imports: [
              CommonModule, TranslateModule, MatProgressSpinnerModule
            ],
            declarations: [
              SpinnerView
            ],
            exports: [
              SpinnerView
            ]
          })
export class CelumSpinnerModule {
}
