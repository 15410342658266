import { Injectable } from '@angular/core';
import { ComponentStore } from '@ngrx/component-store';
import { TranslateService } from '@ngx-translate/core';
import { combineLatest, debounceTime, distinctUntilChanged, of, switchMap, tap } from 'rxjs';

import { tapAllResponses } from '@celum/core';
import { AssetMetadataField } from '@celum/libraries/domain';
import { FilterState } from '@celum/shared/domain';
import { ErrorService } from '@celum/shared/util';

import { MetadataStepService } from '../metadata-step.service';

export type MetadataFilterState = {
  repositoryId: string;
} & FilterState<AssetMetadataField>;

@Injectable()
export class MetadataFilterService extends ComponentStore<MetadataFilterState> {
  public vm$ = this.select(state => state);

  public menuOpened = this.effect<boolean>(menuOpen$ =>
    menuOpen$.pipe(
      tap(value => this.patchState({ loading: true, isOpen: value })),
      switchMap(isMenuOpen => {
        if (isMenuOpen === null) {
          return of([]);
        }
        if (isMenuOpen === false) {
          return of(null);
        }

        this.patchState({ searchValue: '' });

        return combineLatest(
          [this.metadataStepService.loadAvailableMetadata(this.get().repositoryId).pipe(distinctUntilChanged()), this.searchValue$.pipe(debounceTime(300))],
          (metaData: AssetMetadataField[], searchValue: string) =>
            searchValue
              ? metaData.filter(filter => {
                  const translatedLabel = filter.labels[this.translateService.currentLang] || filter.labels[this.translateService.defaultLang];
                  return translatedLabel?.toLowerCase().includes(searchValue.toLowerCase());
                })
              : metaData
        );
      }),
      tapAllResponses(
        (metadata: AssetMetadataField[]) => {
          this.patchState({ loading: false });

          if (metadata) {
            this.patchState({ filteredValues: metadata });
          }
        },
        (error: any) => {
          this.errorService.error(
            `MetadataFilterComponent_${new Date().toISOString()}`,
            `LIBRARIES.CREATE.METADATA.LOAD_METADATA_ERROR.${error?.error?.errorKey}`,
            error,
            true,
            true
          );
          this.patchState({ loading: false, filteredValues: [] });
        }
      )
    )
  );

  protected searchValue$ = this.select(state => state.searchValue);

  constructor(
    private metadataStepService: MetadataStepService,
    private translateService: TranslateService,
    private errorService: ErrorService
  ) {
    super({
      selectedValues: [],
      searchValue: '',
      loading: false,
      filteredValues: [],
      isOpen: false,
      repositoryId: '',
      onTouched: null,
      onChange: null
    });
  }

  public selectionChanged(selectedValues: AssetMetadataField[]): void {
    this.patchState({ selectedValues });
    this.get().onChange?.(selectedValues);
  }
}
