import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, combineLatest, EMPTY, map, Observable, of, switchMap, take } from 'rxjs';

import { tapAllResponses } from '@celum/core';
import { Asset, AssetFilter, LibrariesProperties, LibraryError, LibraryErrorKey, RenditionType } from '@celum/libraries/domain';
import { ErrorService, getFilenameExtension, PagedListState, Result, SimplePagedListService } from '@celum/shared/util';

import { AssetsFilterService } from './assets-filter/assets-filter.service';
import { LibraryDesignerServiceViewModel, LibraryWizardService } from '../../library-wizard.service';

export interface AssetsStepState {
  repositoryId: string;
  currentPage: number;
  filter: AssetFilter;
  initialized: boolean;
  libraryError: LibraryError;
}

export interface AssetsStepViewModel {
  loadingAssets: boolean;
  assets: Asset[];
  totalElementCount: number;
  hasBottom: boolean;
  error: LibraryError;
}

@Injectable()
export class AssetsStepService extends SimplePagedListService<Asset, AssetsStepState> {
  public vm$ = combineLatest([this.state$, this.libraryDesignerService.vm$]).pipe(
    map(([state, libraryDesignerServiceViewModel]) => this.createViewModel(state, libraryDesignerServiceViewModel))
  );

  private checkStoredSearchesAndLoadAssets = this.effect<string>(loadSearches$ =>
    loadSearches$.pipe(
      switchMap((repositoryId: string) => this.assetsFilterService.getStoredSearches(repositoryId)),
      take(1),
      tapAllResponses(
        (storedSearches: AssetFilter[]) => this.processLoadedStoredSearches(storedSearches),
        (error: HttpErrorResponse) => {
          this.errorService.httpError(error, 'AssetsStepService');
        }
      )
    )
  );

  constructor(
    private http: HttpClient,
    private assetsFilterService: AssetsFilterService,
    protected libraryDesignerService: LibraryWizardService,
    private errorService: ErrorService
  ) {
    super(
      {
        serverCall: (offset: number, batchSize: number) => this.getAssets(offset, batchSize),
        batchSize: 20,
        resultMapper: resultData => this.convertAssets(resultData)
      },
      {
        repositoryId: null,
        filter: null,
        currentPage: 0,
        initialized: false,
        libraryError: null
      }
    );
  }

  public init(repositoryId: string, filter: AssetFilter, libraryError: LibraryError): void {
    if (this.get().initialized) {
      return;
    }

    this.patchState({ initialized: true, libraryError, repositoryId, filter });
    if (libraryError?.key === LibraryErrorKey.STORED_SEARCH_NOT_FOUND) {
      return;
    }

    this.checkStoredSearchesAndLoadAssets(repositoryId);
  }

  public loadAssets(repositoryId: string, filter: AssetFilter): void {
    this.patchState({
      repositoryId,
      filter
    });
    this.load();
  }

  private getAssets(offset: number, batchSize: number): Observable<Result<Asset>> {
    return this.get().filter
      ? this.http
          .post(`${LibrariesProperties.properties.apiUrl}/content-hub/assets?repositoryId=${encodeURI(this.get().repositoryId)}`, {
            savedSearchId: this.get().filter.id,
            page: offset / batchSize,
            pageSize: batchSize
          })
          .pipe(
            map((data: any) => ({
              paginationInfo: {
                hasBottom: data.paginationInformation.elementsFollow,
                hasTop: offset / batchSize > 0,
                totalElementCount: data.paginationInformation.totalElementCount
              },
              data: data.results
            })),
            catchError(error => {
              // We know that we should not be able to get assets if the user has no connection to CH, so ignore this specific error
              if (this.get().libraryError?.key === LibraryErrorKey.USER_NOT_CONNECTED && error.status === 409) {
                return EMPTY;
              }
              this.errorService.httpError(error, 'AssetStepService');
              return EMPTY;
            })
          )
      : of({
          data: [],
          paginationInfo: null
        });
  }

  private convertAssets(assets: any[]): Asset[] {
    return assets.map((asset: any) => ({
      fileName: asset.name,
      fileExtension: getFilenameExtension(asset.name),
      filePreviewUrl: of(asset.activeVersion?.renditions?.find((rendition: any) => rendition.type === RenditionType.THUMBNAIL)?.downloadUrl),
      canDownloadOriginal: asset.canDownloadOriginal
    }));
  }

  private processLoadedStoredSearches(storedSearches: AssetFilter[]): void {
    if (storedSearches.some(storedSearch => storedSearch.id === this.get().filter.id)) {
      this.load();
    } else {
      const error: LibraryError = { key: LibraryErrorKey.STORED_SEARCH_NOT_FOUND, errorCount: 0, lastOccurrence: new Date() };
      this.patchState({ libraryError: error });
    }
  }

  private createViewModel(
    state: AssetsStepState & PagedListState<Asset>,
    libraryDesignerServiceViewModel: LibraryDesignerServiceViewModel
  ): AssetsStepViewModel {
    return {
      loadingAssets: state.loading || state.loadingBatch,
      assets: state.data,
      totalElementCount: state.paginationInfo?.totalElementCount || 0,
      hasBottom: state.paginationInfo?.hasBottom,
      error: state.libraryError || libraryDesignerServiceViewModel.library?.error
    };
  }
}
