import { createFeatureSelector, createSelector } from '@ngrx/store';

import { PageContext } from '@celum/common-components';
// keep OperationDefinition as otherwise it results in relative imports from core in the d.ts file!
import { OperationsManager, OperationStoreDefinition } from '@celum/core';

import { MagicButtonActions, MagicButtonActionTypes } from './magic-button-actions';

export class MagicButtonState {
  public featuredOperations: OperationStoreDefinition[] = [];
  public operations: OperationStoreDefinition[] = [];
  public currentContext: PageContext;
}

export function magicButtonReducer(state: MagicButtonState = new MagicButtonState(), action: MagicButtonActions): MagicButtonState {

  switch (action.type) {
    case MagicButtonActionTypes.UpdateContextAction: {
      return {
        ...state,
        currentContext: action.context
      };
    }
    case MagicButtonActionTypes.UpdateOperationsAction: {
      return {
        ...state,
        featuredOperations: action.featured,
        operations: action.operations
      };
    }
  }

  return state;
}

const selectMagicButton = createFeatureSelector<MagicButtonState>('magicButton');

export const getOperations = createSelector(
  selectMagicButton,
  state => state.operations.map(operationDef => OperationsManager.storeDefinitionToOperationDefinition(operationDef))
);

export const getFeaturedOperations = createSelector(
  selectMagicButton,
  state => state.featuredOperations.map(operationDef => OperationsManager.storeDefinitionToOperationDefinition(operationDef))
);

export const getContext = createSelector(
  selectMagicButton,
  state => state.currentContext
);
