<ng-container *ngIf="configuration">

  <celum-icon class="message_icon" [configuration]="configuration.icon"></celum-icon>
  <p class="message_title" [style.color]="configuration.color">
    {{ configuration.title | language:null:true:configuration.titleParams}}
  </p>
  <ng-content *ngIf="!configuration.messages || configuration.messages.length === 0"></ng-content>
  <span *ngFor="let message of configuration.messages" [innerHTML]="message | language:null:true:configuration.messageParams | sanitized">
  </span>

</ng-container>
