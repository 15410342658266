import { ChangeDetectionStrategy, ChangeDetectorRef, Component, HostBinding, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { FormControl } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';

import { ReactiveComponent } from '@celum/ng2base';

const MAX_LENGTH_DEFAULT = 50;

@Component({
             standalone: true,
             imports: [TranslateModule],
             selector: 'celum-remaining-characters',
             templateUrl: './remaining-characters.component.html',
             styleUrls: ['./remaining-characters.component.less'],
             changeDetection: ChangeDetectionStrategy.OnPush
           })
export class CelumRemainingCharactersComponent extends ReactiveComponent implements OnInit, OnChanges {
  @HostBinding('hidden')
  public hostClass = true;

  @Input()
  public maxNameLength = MAX_LENGTH_DEFAULT;
  @Input()
  public control: FormControl<string>;

  protected remainingCharacters = MAX_LENGTH_DEFAULT;

  private valueChangesSubscription: Subscription;

  constructor(private changeDetectorRef: ChangeDetectorRef) {
    super();
  }

  public ngOnInit(): void {
    this.subscribeFormControlValueChanges();
  }

  public ngOnChanges({ maxNameLength, control }: SimpleChanges): void {
    const maxNameLengthChanged = maxNameLength && maxNameLength.previousValue !== this.maxNameLength;
    const formControlChanged = control && control.previousValue !== this.control;

    formControlChanged && this.subscribeFormControlValueChanges();
    if (maxNameLengthChanged || formControlChanged) {
      this.updateRemainingCharacters();
    }
  }

  private subscribeFormControlValueChanges(): void {
    this.valueChangesSubscription?.unsubscribe();
    this.valueChangesSubscription = this.control?.valueChanges.pipe(takeUntil(this.unsubscribe$)).subscribe(() => {
      this.updateRemainingCharacters();
    });
  }

  private updateRemainingCharacters(): void {
    const currentLength = this.control?.value?.length ?? 0;
    this.remainingCharacters = this.maxNameLength - currentLength;
    this.hostClass = this.remainingCharacters >= 11;
    this.changeDetectorRef.markForCheck();
  }
}
