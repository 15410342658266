import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';

import { SnackbarList } from './components/snackbar-list';
import { snackbarReducer } from './store/snackbar-reducer';
import { CelumListModule } from '../list';

export const storeModule = StoreModule.forFeature('snackbar', snackbarReducer);

/**
 * ⚠ Requires dependency `@ngrx/store`.
 */
@NgModule({
            imports: [
              CommonModule, storeModule, CelumListModule
            ],
            declarations: [SnackbarList],
            exports: [SnackbarList]
          })
export class CelumSnackbarListModule {
}
