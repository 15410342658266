import { CommonModule } from '@angular/common';
import { ChangeDetectionStrategy, Component, EventEmitter, HostBinding, Input, Output, ViewEncapsulation } from '@angular/core';
import { InfiniteScrollModule } from 'ngx-infinite-scroll';

import { ClickTapDirective, LongPressDirective } from '@celum/ng2base';


@Component({
             selector: 'celum-list-infinite-scroll-wrapper',
             templateUrl: './infinite-scroll-list-wrapper.component.html',
             styleUrls: ['./infinite-scroll-list-wrapper.component.less'],
             standalone: true,
             imports: [CommonModule, InfiniteScrollModule, ClickTapDirective, LongPressDirective],
             changeDetection: ChangeDetectionStrategy.OnPush,
             encapsulation: ViewEncapsulation.None
           })
export class InfiniteScrollListWrapperComponent {

  @Input() public fromRoot: boolean;

  @Output() public readonly onEmptySpaceClick = new EventEmitter<Event>();
  @Output() public readonly onContextMenu = new EventEmitter<MouseEvent>();
  @Output() public readonly onScroll = new EventEmitter<boolean>();

  @HostBinding('class.infinite-scroll-list-wrapper') public hostCls = true;

}
