<div *ngIf="vm$ | async as vm" class="libs-dashboard-info-header">
  <div *ngIf="vm.appliedFilters.length === 0" class="libs-dashboard-info-header--not-applied">
    <span class="libs-dashboard-info-header_total-number" data-test-dashboard-info-header-total-number>{{ vm.totalNumberOfItems }}&nbsp;</span>
    <span class="libs-dashboard-info-header_text">{{ (vm.totalNumberOfItems === 1 ? 'LIBRARIES.LIBRARY' : 'LIBRARIES.LIBRARIES') | translate }}</span>
    <div *ngIf="vm.numberOfFilteredItems > 0">
      <span class="libs-dashboard-info-header_text">
        {{
          (vm.totalNumberOfItems === 1 ? 'LIBRARIES.DASHBOARD.FILTERS.OWN_LIBRARY_HEADER_TEXT' : 'LIBRARIES.DASHBOARD.FILTERS.OWN_LIBRARIES_HEADER_TEXT')
            | translate
        }}
      </span>
      <span
        class="libs-dashboard-info-header_link"
        data-test-dashboard-info-header-filter-link
        [innerHTML]="
          (vm.totalNumberOfItems === 1 ? 'LIBRARIES.DASHBOARD.FILTERS.OWN_LIBRARY_FILTER_LINK' : 'LIBRARIES.DASHBOARD.FILTERS.OWN_LIBRARIES_FILTER_LINK')
            | translate: { numberOfOwnLibraries: vm.numberOfFilteredItems }
        "
        (click)="applyCreatedByFilter(vm.userName, vm.userOid)"></span>
      <span>.</span>
    </div>
  </div>

  <celum-chip-set *ngIf="vm.appliedFilters.length > 0" (clearAllClicked)="filterChanged.emit([])">
    <span class="libs-dashboard-info-header_chips_prefix" prefix>
      <span class="libs-dashboard-info-header_total-number">{{ vm.numberOfFilteredItems }}&nbsp;</span>
      <span class="libs-dashboard-info-header_text">
        {{ (vm.totalNumberOfItems === 1 ? 'LIBRARIES.DASHBOARD.FILTERS.LIBRARY_FILTERED' : 'LIBRARIES.DASHBOARD.FILTERS.LIBRARIES_FILTERED') | translate }}
      </span>
    </span>
    <celum-chip
      *ngFor="let filter of vm.appliedFilters; trackBy: trackFn"
      data-test-dashboard-info-header-user-chip
      [attr.data-test-dashboard-info-header-user-chip]="filter.displayValue || filter.value"
      [prefix]="filter.titleKey | translate"
      [text]="filter.displayValue || filter.value"
      (remove)="removeFilter(filter)"></celum-chip>
  </celum-chip-set>
</div>
