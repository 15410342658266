import { CelumPropertiesProvider } from '@celum/core';

export interface CelumLibrariesProperties {
  apiUrl: string;
  saccWebUrl: string;
  featureFlags?: {
    enableTransparentBackgroundImagesVariants?: boolean;
  };
}

export class LibrariesProperties {
  static get properties(): CelumLibrariesProperties {
    const testingFallback = {
      apiUrl: `test-api`,
      saccWebUrl: `test-sacc-web-url`
    };
    return CelumPropertiesProvider.properties.appProperties?.libraries ?? testingFallback;
  }
}
