import { Component, Input, OnDestroy, ViewEncapsulation } from '@angular/core';

import { ReactiveComponent } from '@celum/ng2base';

import { IconConfiguration } from '../../icon/index';
import { EmptyPageConfig } from '../empty-page-config';

@Component({
             selector: 'empty-page',
             templateUrl: './empty-page.html',
             styleUrls: ['./empty-page.less'],
             encapsulation: ViewEncapsulation.None
           })
export class EmptyPage extends ReactiveComponent implements OnDestroy {
  @Input() public config: EmptyPageConfig;

  constructor() {
    super();
  }

  public ngOnDestroy(): void {
    super.ngOnDestroy();
  }

  public actionBtnClicked(): void {
    this.config.callback && this.config.callback();
  }

  public static defaultConfig(
    id: string,
    icon: string,
    messageKey: string,
    textSize: 'small' | 'normal' = 'normal',
    actionTextKey: string,
    callback: (args?: any) => any,
    actionIcon?: IconConfiguration,
    iconSize = 150,
  ): EmptyPageConfig {
    const iconConf = new IconConfiguration(icon).withIconSize(iconSize);
    return new EmptyPageConfig(id, iconConf, messageKey, textSize, actionTextKey, true, 'button', callback, actionIcon);
  }

  public static noActionConfig(
    id: string,
    icon: string,
    messageKey: string,
    textSize: 'small' | 'normal' = 'normal',
    iconSize = 150,
    maxWidth?: string
  ): EmptyPageConfig {
    const iconConf = new IconConfiguration(icon).withIconSize(iconSize);
    return new EmptyPageConfig(id, iconConf, messageKey, textSize, 'NO_KEY', false, 'button', null, null, maxWidth);
  }

  /**
   * @deprecated
   */
  public static noButtonConfig(id: string, icon: string, messageKey: string, textSize: 'small' | 'normal' = 'normal', iconSize = 150): EmptyPageConfig {
    const iconConf = new IconConfiguration(icon).withIconSize(iconSize);
    return new EmptyPageConfig(id, iconConf, messageKey, textSize, 'NO_KEY', false, null, null);
  }

  public static linkConfig(
    id: string,
    icon: string,
    messageKey: string,
    textSize: 'small' | 'normal' = 'normal',
    iconSize = 150,
    actionTextKey: string,
    callback: (args?: any) => any,
    maxWidth?: string
  ): EmptyPageConfig {
    const iconConf = new IconConfiguration(icon).withIconSize(iconSize);
    return new EmptyPageConfig(id, iconConf, messageKey, textSize, actionTextKey, true, 'link', callback, null, maxWidth);
  }
}
