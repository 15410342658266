import { produce } from 'immer';

import { SnackbarActions, SnackbarActionTypes } from '@celum/common-components';

import { initialSnackbarState, SnackbarCreationInformation, SnackbarReducerState } from './snackbar-state';

export function snackbarReducer(state: SnackbarReducerState = initialSnackbarState, action: SnackbarActions): SnackbarReducerState {
  return produce(state, draft => {

    switch (action.type) {
      case SnackbarActionTypes.RemoveSnackbar: {
        delete draft[action.id];
        break;
      }
      case SnackbarActionTypes.ShowSnackbar: {
        if (draft[action.id]) {
          draft[action.id].config = action.config;
        } else {
          draft[action.id] = {
            id: action.id,
            config: action.config,
            placeOnTop: action.placeOnTop
          } as SnackbarCreationInformation;
        }
        break;
      }
    }

  });
}
